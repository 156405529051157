import React from 'react';
import styles from './Main.module.css';
import cat1 from '../../../../assets/cat_1.jpg';
import cat2 from '../../../../assets/cat-2.jpg';
import { useHistory } from 'react-router-dom';

const data = [
    {
        id: 1,
        name: 'Командные виды спорта',
        img: cat1,
    },
    {
        id: 2,
        name: 'Все виды спорта',
        img: cat2,
    },
    {
        id: 3,
        name: 'Командные виды спорта',
        img: cat1,
    },
    {
        id: 4,
        name: 'Все виды спорта',
        img: cat2,
    },
    {
        id: 5,
        name: 'Командные виды спорта',
        img: cat1,
    },
    {
        id: 6,
        name: 'Все виды спорта',
        img: cat2,
    },
]

const Main = () => {
    const history = useHistory();

    return (
        <>
            <h1 className={styles.title}>Спортивные товары</h1>
            <div className={styles.category_container}>
                {data.map((c) =>
                    <div key={c.id} onClick={() => history.push(`/market/${c.name}`)} className={styles.category_card}>
                        <img src={c.img} />
                        <div className={styles.category_name}>{c.name}</div>
                    </div>
                )}
            </div>
        </>
    )
}

export default Main;