import { GET_PLAYER_INFO, GET_PROFILE, LOGIN, SET_ALERT } from '.';
import { ApiUrl } from '../../config';
import { getAccessToken, httpReguest } from '../../utils/httpFunctions';
import { initialAlertState } from "../reducers/alert";

export const LEAVE_LOADING = "LEAVE_LOADING";


export const leaveClubPlayer = (clubId, playerId, newCaptainId, navigate) => async (dispatch) => {
	try {
		if (getAccessToken() == "") {
			dispatch({ type: LOGIN, payload: false })
			dispatch({ type: GET_PROFILE, payload: false })
			dispatch({ type: GET_PLAYER_INFO, payload: null })
		}
		dispatch({ type: LEAVE_LOADING, payload: true })
		const apiData = {
			clubId,
			playerId,
			newCaptainId
		}

		const apiConfig = {
			method: "POST",
			headers: {
				"Accept": "application/json",
			},
			body: JSON.stringify(apiData),
		}
		const response = await httpReguest(`${ApiUrl}client/club/player/leave`, apiConfig)
		dispatch({
			type: SET_ALERT,
			payload: { ...initialAlertState, open: true, message: 'Вы успешно покинули команду', type: "success", position: initialAlertState.position }
		}
		)
		navigate()

	} catch (e) {
		dispatch({
			type: SET_ALERT,
			payload: { ...initialAlertState, open: true, message: e.apiResponse.message, type: "error", position: initialAlertState.position }
		})
		console.log(e)
	} finally {
		dispatch({ type: LEAVE_LOADING, payload: false })
	}

}