export const getAccessToken = () => localStorage.getItem("access-token");

export const logout = () => {
	localStorage.removeItem("access-token");
}

export const httpReguest = async (url, apiConfig) => {
	try {
		apiConfig.headers.Authorization = `${getAccessToken()}`
		const response = await fetch(url, apiConfig);
		const responseJson = await response.json();

		if (response.ok) {
			return responseJson;
		} else if (response.status === 401) {
			logout();
		}  else {
			const error = {
				message: "Возникла ошибка во время запроса на сервер",
				apiResponse: responseJson,
			};

			throw error;
		}
	} catch (error) {
		throw error;
	}
}



export const getMonth = (getDate) => {
	const date = getDate?.split(" ")[0].split(".");
	const hour = getDate?.split(" ")[1] !== undefined ? getDate?.split(" ")[1] : ``;
	const day = date[2]
	let num = date[1]
	if (num === "01") {
		return day + " Января" + "\n" + hour
	} else if (num === "02") {
		return day + " Февраля" + "\n" + hour
	} else if (num === "03") {
		return day + " Марта" + "\n" + hour
	} else if (num === "04") {
		return day + " Апреля" + "\n" + hour
	} else if (num === "05") {
		return day + " Мая" + "\n" + hour
	} else if (num === "06") {
		return day + " Июня" + "\n" + hour
	} else if (num === "07") {
		return day + " Июля" + "\n" + hour
	} else if (num === "08") {
		return day + " Августа" + "\n" + hour
	} else if (num === "09") {
		return day + " Сентября" + "\n" + hour
	} else if (num === "10") {
		return day + " Октября" + "\n" + hour
	} else if (num === "11") {
		return day + " Ноября" + "\n" + hour
	} else {
		return day + " Декабря" + "\n" + hour
	}
}


export const parsePhone = (phone) => {
	return phone.replaceAll("(", "").replaceAll(")", "").replaceAll(" ", "").replaceAll("-", "");
}

export const phonePormat = (phone) => {
	if (phone) {
		let word = "";

		for (let i = 0; i < phone.length; i++) {
			if (i % 3 === 0 && i !== phone.length - 1 && i !== 0) {
				word += phone[i] + "-";
				continue;
			}
			word += phone[i];
		}

		return word;
	}
}