import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import c from './ClubViewModal.module.css'
import { Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import ClubCardWithName from '../../../../../utils/ClubCardWithName';
import { ApiUrl, imgUrl } from '../../../../../config';
import { phonePormat } from '../../../../../utils/httpFunctions';
import { parseImagePath } from '../../../Players/Players';
import Loader from '../../../../../utils/Loader';
import PlayerItem from '../../components/PlayerItemCard/PlayerItem';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const ClubViewModal = ({ show, onClose, clubId }) => {
    const dispatch = useDispatch();
    const [clubInfo, setClubInfo] = useState(null);
    const [clubPlayers, setClubPlayers] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (show) {
            ((async () => {
                try {
                    console.log(clubId)
                    const apiData = {
                        id: clubId?.toString(),
                        name: '',
                        phone: "",
                        statusId: "",
                        rowsLimit: 1,
                        rowsOffset: 0
                    }
                    setLoading(true);
                    const response = await fetch(`${ApiUrl}client/clubs`, {
                        method: 'POST',
                        headers: {
                            "Accept": "application/json",
                        },
                        body: JSON.stringify(apiData),
                    });
                    const responseJson = await response.json();
                    setClubInfo(responseJson.payload.clubs[0])
                } catch (e) {
                    console.log(e);
                } finally {
                    setLoading(false)
                }
            })())
            const getPlayersByClub = async () => {
                try {
                    setLoading(true);
                    const response = await fetch(`${ApiUrl}client/club/players/${clubId}`, {
                        method: 'GET',
                        headers: {
                            "Accept": "application/json",
                        },
                    });
                    const responseJson = await response.json();
                    setClubPlayers(responseJson.payload.players)
                } catch (e) {
                    console.log(e);
                } finally {
                    setLoading(false)
                }
            }
            getPlayersByClub()
        }
    }, [show])


    if (show) {


        return (
            <div>
                <Dialog maxWidth={"md"} fullWidth aria-labelledby="customized-dialog-title" open={show} className={c.container}>
                    <DialogTitle id="customized-dialog-title" onClose={onClose} className={c.title}>
                        Подробная информация
                    </DialogTitle>
                    <DialogContent dividers className={c.content}>
                        {loading || !clubInfo ? <Loader /> :
                            <>
                                <Grid container>
                                    <Grid item xs={5} md={5}>
                                        <div className={c.avatar}>
                                            {clubInfo.imagePath == "" ? <ClubCardWithName name={clubInfo.name} width={150} height={150} fontSize={'1.25rem'} /> :
                                                <img src={`${imgUrl}${parseImagePath(clubInfo.imagePath)}`} />
                                            }
                                            <div className={c.fullName}>
                                                {clubInfo.name}
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item xs={7} md={7}>
                                        <div className={c.info}>
                                            <div className={c.row}>
                                                <div className={c.key}>Капитан:</div>
                                                <div className={c.value}>{clubInfo.manager}</div>
                                            </div>
                                            <div className={c.row}>
                                                <div className={c.key}>Город:</div>
                                                <div className={c.value}>{clubInfo.city}</div>
                                            </div>
                                            <div className={c.row}>
                                                <div className={c.key}>Игры:</div>
                                                <div className={c.value}>{clubInfo.totalGame}</div>
                                            </div>
                                            <div className={c.row}>
                                                <div className={c.key}>Победа:</div>
                                                <div className={c.value}>{clubInfo.winGame}</div>
                                            </div>
                                            <div className={c.row}>
                                                <div className={c.key}>Поражение:</div>
                                                <div className={c.value}>{clubInfo.loseGame}</div>
                                            </div>
                                            <div className={c.row}>
                                                <div className={c.key}>Ничья:</div>
                                                <div className={c.value}>{clubInfo.drawGame}</div>
                                            </div>
                                            <div className={c.row}>
                                                <div className={c.key}>Телефон:</div>
                                                <div className={c.value}>{phonePormat(clubInfo.phone)}</div>
                                            </div>

                                        </div>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={12}>
                                        {clubPlayers?.length > 0 ?
                                            <>
                                                <div className={c.clubs_histroy_title}>
                                                    Список игроков:
                                                </div>
                                                <div className={c.club_history_keys}>
                                                    <div className={c.key_item}>Игрок</div>
                                                    <div className={c.key_item}>Номер</div>
                                                    <div className={c.key_item}>Позиция</div>
                                                </div>
                                                <div className={c.history_container}>
                                                    {clubPlayers?.map((player) =>
                                                        <PlayerItem key={player.id} fullName={player?.fullname} number={player?.number} position={player?.position} img={player?.image} />
                                                    )}
                                                </div>
                                            </>
                                            : null
                                        }
                                    </Grid>
                                </Grid>
                            </>
                        }

                    </DialogContent>
                </Dialog>
            </div>
        );
    }

    return <div></div>
}

export default ClubViewModal;