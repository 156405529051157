import { CHANGE_LOADER, GET_CHAMPIONSHIP_CLUBS, GET_CHAMPIONSHIP_LIST, SELECT_CHAMPIONSHIP, SET_CHAMPIONSHIP_ITEM_NAME, SET_TOSS } from '../actions'


const initialState = {
	championshipList: [],
	loader: false,
	selectChampionship: [],
	championshipClubs: [],
	toss: false,
	name: '',
}


const championshipReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_CHAMPIONSHIP_LIST:
			return { ...state, championshipList: action.payload }
		case CHANGE_LOADER:
			return { ...state, loader: action.payload }
		case SELECT_CHAMPIONSHIP:
			return { ...state, selectChampionship: action.payload }
		case GET_CHAMPIONSHIP_CLUBS:
			return { ...state, championshipClubs: action.payload }
		case SET_TOSS:
			return { ...state, toss: action.payload }
		case SET_CHAMPIONSHIP_ITEM_NAME:
			return { ...state, name: action.payload }
		default:
			return state;
	}
}

export default championshipReducer;