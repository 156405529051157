export const Games16 = [
	{ id: 0, championshipId: 0, clubId1: 0, clubId1Name: "", clubId1Picture: "", clubId1Score: 0, clubId2: 0, clubId2Name: "", clubId2Picture: "", clubId2Score: 0, stadium: "", stage: 1, game: 1, gameDate: "", winner: 0, winnerName: "", createdDate: "" },
	{ id: 0, championshipId: 0, clubId1: 0, clubId1Name: "", clubId1Picture: "", clubId1Score: 0, clubId2: 0, clubId2Name: "", clubId2Picture: "", clubId2Score: 0, stadium: "", stage: 2, game: 1, gameDate: "", winner: 0, winnerName: "", createdDate: "" },
	{ id: 0, championshipId: 0, clubId1: 0, clubId1Name: "", clubId1Picture: "", clubId1Score: 0, clubId2: 0, clubId2Name: "", clubId2Picture: "", clubId2Score: 0, stadium: "", stage: 2, game: 2, gameDate: "", winner: 0, winnerName: "", createdDate: "" },
	{ id: 0, championshipId: 0, clubId1: 0, clubId1Name: "", clubId1Picture: "", clubId1Score: 0, clubId2: 0, clubId2Name: "", clubId2Picture: "", clubId2Score: 0, stadium: "", stage: 3, game: 1, gameDate: "", winner: 0, winnerName: "", createdDate: "" },
	{ id: 0, championshipId: 0, clubId1: 0, clubId1Name: "", clubId1Picture: "", clubId1Score: 0, clubId2: 0, clubId2Name: "", clubId2Picture: "", clubId2Score: 0, stadium: "", stage: 3, game: 2, gameDate: "", winner: 0, winnerName: "", createdDate: "" },
	{ id: 0, championshipId: 0, clubId1: 0, clubId1Name: "", clubId1Picture: "", clubId1Score: 0, clubId2: 0, clubId2Name: "", clubId2Picture: "", clubId2Score: 0, stadium: "", stage: 3, game: 3, gameDate: "", winner: 0, winnerName: "", createdDate: "" },
	{ id: 0, championshipId: 0, clubId1: 0, clubId1Name: "", clubId1Picture: "", clubId1Score: 0, clubId2: 0, clubId2Name: "", clubId2Picture: "", clubId2Score: 0, stadium: "", stage: 3, game: 4, gameDate: "", winner: 0, winnerName: "", createdDate: "" },
	{ id: 0, championshipId: 0, clubId1: 0, clubId1Name: "", clubId1Picture: "", clubId1Score: 0, clubId2: 0, clubId2Name: "", clubId2Picture: "", clubId2Score: 0, stadium: "", stage: 4, game: 1, gameDate: "", winner: 0, winnerName: "", createdDate: "" },
	{ id: 0, championshipId: 0, clubId1: 0, clubId1Name: "", clubId1Picture: "", clubId1Score: 0, clubId2: 0, clubId2Name: "", clubId2Picture: "", clubId2Score: 0, stadium: "", stage: 4, game: 2, gameDate: "", winner: 0, winnerName: "", createdDate: "" },
	{ id: 0, championshipId: 0, clubId1: 0, clubId1Name: "", clubId1Picture: "", clubId1Score: 0, clubId2: 0, clubId2Name: "", clubId2Picture: "", clubId2Score: 0, stadium: "", stage: 4, game: 3, gameDate: "", winner: 0, winnerName: "", createdDate: "" },
	{ id: 0, championshipId: 0, clubId1: 0, clubId1Name: "", clubId1Picture: "", clubId1Score: 0, clubId2: 0, clubId2Name: "", clubId2Picture: "", clubId2Score: 0, stadium: "", stage: 4, game: 4, gameDate: "", winner: 0, winnerName: "", createdDate: "" },
	{ id: 0, championshipId: 0, clubId1: 0, clubId1Name: "", clubId1Picture: "", clubId1Score: 0, clubId2: 0, clubId2Name: "", clubId2Picture: "", clubId2Score: 0, stadium: "", stage: 4, game: 5, gameDate: "", winner: 0, winnerName: "", createdDate: "" },
	{ id: 0, championshipId: 0, clubId1: 0, clubId1Name: "", clubId1Picture: "", clubId1Score: 0, clubId2: 0, clubId2Name: "", clubId2Picture: "", clubId2Score: 0, stadium: "", stage: 4, game: 6, gameDate: "", winner: 0, winnerName: "", createdDate: "" },
	{ id: 0, championshipId: 0, clubId1: 0, clubId1Name: "", clubId1Picture: "", clubId1Score: 0, clubId2: 0, clubId2Name: "", clubId2Picture: "", clubId2Score: 0, stadium: "", stage: 4, game: 7, gameDate: "", winner: 0, winnerName: "", createdDate: "" },
	{ id: 0, championshipId: 0, clubId1: 0, clubId1Name: "", clubId1Picture: "", clubId1Score: 0, clubId2: 0, clubId2Name: "", clubId2Picture: "", clubId2Score: 0, stadium: "", stage: 4, game: 8, gameDate: "", winner: 0, winnerName: "", createdDate: "" },
]
export const Games8 = [
	{ id: 0, championshipId: 0, clubId1: 0, clubId1Name: "", clubId1Picture: "", clubId1Score: 0, clubId2: 0, clubId2Name: "", clubId2Picture: "", clubId2Score: 0, stadium: "", stage: 1, game: 1, gameDate: "", winner: 0, winnerName: "", createdDate: "" },
	{ id: 0, championshipId: 0, clubId1: 0, clubId1Name: "", clubId1Picture: "", clubId1Score: 0, clubId2: 0, clubId2Name: "", clubId2Picture: "", clubId2Score: 0, stadium: "", stage: 2, game: 1, gameDate: "", winner: 0, winnerName: "", createdDate: "" },
	{ id: 0, championshipId: 0, clubId1: 0, clubId1Name: "", clubId1Picture: "", clubId1Score: 0, clubId2: 0, clubId2Name: "", clubId2Picture: "", clubId2Score: 0, stadium: "", stage: 2, game: 2, gameDate: "", winner: 0, winnerName: "", createdDate: "" },
	{ id: 0, championshipId: 0, clubId1: 0, clubId1Name: "", clubId1Picture: "", clubId1Score: 0, clubId2: 0, clubId2Name: "", clubId2Picture: "", clubId2Score: 0, stadium: "", stage: 3, game: 1, gameDate: "", winner: 0, winnerName: "", createdDate: "" },
	{ id: 0, championshipId: 0, clubId1: 0, clubId1Name: "", clubId1Picture: "", clubId1Score: 0, clubId2: 0, clubId2Name: "", clubId2Picture: "", clubId2Score: 0, stadium: "", stage: 3, game: 2, gameDate: "", winner: 0, winnerName: "", createdDate: "" },
	{ id: 0, championshipId: 0, clubId1: 0, clubId1Name: "", clubId1Picture: "", clubId1Score: 0, clubId2: 0, clubId2Name: "", clubId2Picture: "", clubId2Score: 0, stadium: "", stage: 3, game: 3, gameDate: "", winner: 0, winnerName: "", createdDate: "" },
	{ id: 0, championshipId: 0, clubId1: 0, clubId1Name: "", clubId1Picture: "", clubId1Score: 0, clubId2: 0, clubId2Name: "", clubId2Picture: "", clubId2Score: 0, stadium: "", stage: 3, game: 4, gameDate: "", winner: 0, winnerName: "", createdDate: "" },
]