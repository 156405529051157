import { REDIRECT } from "../actions"


const initialState = {
    redirect: false,
}


export const signUpReducer = (state = initialState, action) => {
    switch (action.type) {
        case REDIRECT:
            return {...state, redirect: action.payload};
        default:
            return state;    
    }
}