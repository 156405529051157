import { CHANGE_LOADER, GET_GAMES,GET_STATUS,SET_GAME_DATE,SET_INIT_DATE,SET_PREMIRE } from "../actions"


const initialData = {
    loading: false,
    games: [],
    premiere: null,
    gameDate: null,
    status: [],
    initDate: "",
}

const homeReducer = (state = initialData, action) => {
    switch(action.type) {
        case GET_GAMES:
            return {...state, games: action.payload}
        case CHANGE_LOADER:
            return {...state, loading: action.payload}
        case SET_PREMIRE: 
            return {...state, premiere: action.payload} 
        case SET_GAME_DATE: 
            return {...state, gameDate: action.payload}    
        case GET_STATUS: 
            return {...state, status: action.payload} 
        case SET_INIT_DATE:
            return {...state, initDate: action.payload}        
        default:
            return state;
    }
}

export default homeReducer;