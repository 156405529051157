import { SET_ALERT } from "../actions";

export const initialAlertState = {
    open: false,
    message: "",
    type: "success",
    position: {
        vertical: "top",
        horizontal: "center",
    }
};


export const alertReducer = (state = initialAlertState, action) => {
    switch (action.type) {
        case SET_ALERT:
            return action.payload
        default:
            return state;    
    }
}