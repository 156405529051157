import React, { useState } from 'react'
import styles from './PhoneHide.module.css';


const PhoneHide = ({ phone }) => {
	const [show, setShow] = useState(false)

	if (!phone) {
		return null
	}
	return (
		<>
			<span onClick={(e) => { e.stopPropagation(); setShow(true) }} className={styles.phone}>{show ? phone : `${phone?.slice(0, 7)}XXXXXX`}</span>
		</>
	)
}

export default PhoneHide;