import { lazy } from 'react';


export const Header = lazy(() => import("../Header/Header"))
export const Footer = lazy(() => import("../Footer/Footer"))
export const Home = lazy(() => import("../main/Home/Home"))
export const Premiere = lazy(() => import("../main/Home/Premiere"))
export const Clubs = lazy(() => import("../main/Clubs/Clubs"))
export const ClubItem = lazy(() => import("../main/Clubs/ClubItem"))
export const Players = lazy(() => import("../main/Players/Players"))
export const Stadiums = lazy(() => import("../main/Stadiums/Stadiums"))
export const StadiumItem = lazy(() => import("../main/Stadiums/StadiumItem"))
export const Championship = lazy(() => import("../main/Championship/Championship"))
export const ChampionshipItem = lazy(() => import("../main/Championship/ChampionshipItems/ChampionShipItem"))
export const ProfileInfo = lazy(() => import("../main/Profile/ProfileInfo"))
export const MyClub = lazy(() => import("../main/MyClub/MyClub"))
export const RegisterPlayer = lazy(() => import("../main/Register/RegisterPlayer"))
export const RegisterClub = lazy(() => import("../main/Register/RegisterClub"))
export const InDeveloping = lazy(() => import("../InDeveloping/InDeveloping"))
export const Schools = lazy(() => import("../main/Schools/Schools"))
export const Services = lazy(() => import("../main/Services/Services"))