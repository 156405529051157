import { combineReducers } from 'redux';
import { alertReducer } from './alert';
import { loginReducer } from './auth';
import championshipReducer from './championship';
import clubsListReducer from './club';
import homeReducer from './home';
import { invitersReducer } from './inviters';
import playersListReducer from './player';
import { schoolsReducer } from './schools';
import { servicesReducer } from './services';
import { signUpReducer } from './signUp';
import { stadiumsListReducer } from './stadium';
import { leaveClubReducer } from './leaveClub';
import { setScoreReducer } from './setScore';

export const reducer = combineReducers({
	playersListReducer,
	homeReducer,
	clubsListReducer,
	stadiumsListReducer,
	loginReducer,
	signUpReducer,
	invitersReducer,
	alertReducer,
	championshipReducer,
	schoolsReducer,
	servicesReducer,
	leaveClubReducer,
	setScoreReducer,
});