import { makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react'


const useStyles = makeStyles({
	root: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		borderRadius: "50%",
		color: "rgb(0,0,0)",
	},
});


const ClubCardWithName = ({ name, width = 50, height = 50, fontSize = "18px", staticSize = false }) => {
	const classes = useStyles()
	const [clubNameLength, setClubNameLength] = useState(0);
	const getWordsName = (idx) => {
		if (name) {
			let str = "";
			let arrName = name?.split(" ");
			for (let i = 0; i < idx; i++) {
				str += arrName[i][0]?.toUpperCase();
			}
			return str;
		}
		return "";
	}
	useEffect(() => {
		if (name) {
			setClubNameLength(name.split(" ").length)
		}
	}, [name])

	return (
		<div className={classes.root} style={{ background: `#ACABB3`, width: (staticSize ? '100%' : width), height: (staticSize ? '100%' : height), color: '#000000' }}>
			<div style={{ fontSize: (staticSize ? 'auto' : fontSize), textAlign: 'center', verticalAlign: 'center', fontWeight: "bolder" }}>{getWordsName(clubNameLength)}</div>
		</div>
	)
}

export default ClubCardWithName;