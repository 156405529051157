import { LOGIN_LOADER, GET_PLAYER_INFO, LOGIN, GET_PROFILE, GET_CLUB_INFO, HAS_CLUB, CHANGE_AVATAR_LOGO } from "../actions"


const initialData = {
    playerInfo: null,
    auth: false,
    loading: false,
    profile: false,
    myClubInfo: null,
    hasClub: false,
    avatarLogo: null,
}

export const loginReducer = (state = initialData, action) => {
    switch (action.type) {
        case LOGIN:
            return {...state, auth: action.payload}
        case GET_PLAYER_INFO:
            return {...state, playerInfo: action.payload}
        case CHANGE_AVATAR_LOGO: 
            return {...state, avatarLogo: action.payload}   
        case LOGIN_LOADER:
            return {...state, loading: action.payload}    
        case GET_PROFILE:
            return {...state, profile: action.payload}
        case GET_CLUB_INFO:
            return {...state, myClubInfo: action.payload} 
        case HAS_CLUB:
            return {...state, hasClub: action.payload}          
        default:
            return state
    }
}