import { CHANGE_LOADER, ERROR_CLUB, GET_CLUBS, GET_CLUB_ID, GET_GAME_BY_CLUB, PAGINATION_CLUB} from "../actions";


const initialState = {
    loading : false,
    clubs: [],
    pagination: 0,
    error: false,
    clubId: 0,
    gameListById: [],
}

const clubsListReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_CLUBS:
            return {...state, clubs : action.payload}
        case CHANGE_LOADER:
            return {...state, loading : action.payload} 
        case PAGINATION_CLUB:
            return {...state, pagination: action.payload} 
        case ERROR_CLUB:
            return {...state, error: action.payload}    
        case GET_CLUB_ID:
            return {...state, clubId: action.payload}
        case GET_GAME_BY_CLUB:
            return {...state, gameListById: action.payload}         
        default:
            return state;   
    }
}

export default clubsListReducer;
