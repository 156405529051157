import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router';
import SnackbarAlert from '../../utils/SnackbarAlert';
import { Footer, Header, InDeveloping, Championship, ChampionshipItem, ClubItem, Clubs, MyClub, Players, ProfileInfo, RegisterClub, RegisterPlayer, Schools, StadiumItem, Stadiums, Services } from "../Lazy/Lazy";
import Home from '../main/Home/Home';
import ContentRouter from '../routes/ContentRouter';
import SuspenseFallback from '../SuspenseFallback/SuspenseFallback';
import './App.css'
import Main from '../main/Market/Main/Main';
import CategoryItem from '../main/Market/CategoryItem/CategoryItem';


function App() {

	return (
		<>
			<Suspense fallback={<SuspenseFallback />}>
				<Header />
				<main>
					<Switch>
						<ContentRouter path='/' exact>
							<Home />
						</ContentRouter>
						<ContentRouter path='/clubs' exact>
							<Clubs />
						</ContentRouter>
						<Route path="/clubs/:id" exact>
							<ClubItem />
						</Route>
						<ContentRouter path='/players' exact>
							<Players />
						</ContentRouter>
						<ContentRouter path='/stadiums' exact>
							<Stadiums />
						</ContentRouter>
						<Route path="/stadiums/:id" exact>
							<StadiumItem />
						</Route>
						<ContentRouter path="/championships" exact>
							<Championship />
						</ContentRouter>
						<Route path="/championships/:id" exact>
							<ChampionshipItem />
						</Route>
						<Route path="/profile" exact>
							<ProfileInfo />
						</Route>
						<Route path="/club" exact>
							<MyClub />
						</Route>
						<Route path="/player-sign-up" exact>
							<RegisterPlayer />
						</Route>
						<Route path="/club-create" exact>
							<RegisterClub />
						</Route>
						<ContentRouter path="/schools" exact>
							<Schools />
						</ContentRouter>
						<ContentRouter path="/services" exact>
							<Services />
						</ContentRouter>
						<ContentRouter path="/market" exact>
							<Main/>							
						</ContentRouter>
						<ContentRouter path="/market/:category" exact>
							<CategoryItem/>							
						</ContentRouter>
						<Route path="*">
							<InDeveloping />
						</Route>
					</Switch>
				</main>
				<Footer />
			</Suspense>
			<SnackbarAlert />
		</>
	);
}

export default App;
