import { GET_PLAYER_INFO, GET_PROFILE, LOGIN, SET_ALERT } from '.';
import { ApiUrl } from '../../config';
import { getAccessToken, httpReguest } from '../../utils/httpFunctions';
import { initialAlertState } from "../reducers/alert";

export const SET_SCORE_LOADING = "SET_SCORE_LOADING";



export const setScoreGame = (data, onClose, closeModal) => async (dispatch) => {
	try {
		if (getAccessToken() == "") {
			dispatch({ type: LOGIN, payload: false })
			dispatch({ type: GET_PROFILE, payload: false })
			dispatch({ type: GET_PLAYER_INFO, payload: null })
		}
		dispatch({ type: SET_SCORE_LOADING, payload: true })
		const apiData = {...data};
        apiData.inviterScore = +apiData.inviterScore
        apiData.inviteeScore = +apiData.inviteeScore

		const apiConfig = {
			method: "POST",
			headers: {
				"Accept": "application/json",
			},
			body: JSON.stringify(apiData),
		}
		const response = await httpReguest(`${ApiUrl}client/game/score`, apiConfig)
		dispatch({
			type: SET_ALERT,
			payload: { ...initialAlertState, open: true, message: 'Вы успешно назначили счет игры', type: "success", position: initialAlertState.position }
		}
		)
		onClose()
        closeModal()

	} catch (e) {
		dispatch({
			type: SET_ALERT,
			payload: { ...initialAlertState, open: true, message: e.apiResponse.message, type: "error", position: initialAlertState.position }
		})
		console.log(e)
	} finally {
		dispatch({ type: SET_SCORE_LOADING, payload: false })
	}

}