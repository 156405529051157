import { Container, Grid } from '@material-ui/core';
import React from 'react'
import { Route } from 'react-router';
import c from './ContentRouter.module.css'
import boy1 from '../../assets/streetFootball3.jpg'
import boy2 from '../../assets/streetFootball1.jpg'
import { SkletonImg } from '../../utils/SkletonImg';

const ContentRouter = ({path, children}) => {
    return (
        <Route path={path}>
            <Container maxWidth={false} style={{padding: 0}}>
                <Grid container className={c.containerBlock}>
                    <Grid item xs={false} md={2} lg={2} xl={2} className={c.block1}>
                        <div className={c.left}>
                            <SkletonImg path={boy1} skletonClass={c.leftImgSkleton} type="rect"/>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={8} lg={8} xl={8} >
                        <div className={`${c.middleBlock}`}>
                            {children}
                        </div>
                    </Grid>
                    <Grid item xs={12} md={2} lg={2} xl={2} className={c.block2}>
                        <div className={c.right}>
                            <SkletonImg path={boy2} skletonClass={c.rightImgSkleton} type="rect"/>
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </Route>
    )
}

export default ContentRouter;