import React from 'react';
import styles from './CategoryItem.module.css';
import { useParams, useHistory } from 'react-router-dom';
import first from '../../../../assets/market_products/1.jpg';
import second from '../../../../assets/market_products/2.jpg';
import third from '../../../../assets/market_products/3.jpg';
import fourth from '../../../../assets/market_products/4.jpg';
import fiveth from '../../../../assets/market_products/5.jpg';
import sixth from '../../../../assets/market_products/6.jpg';

const data = [
    {
        name: 'Командные виды спорта',
        items: [
            {
                id: 1,
                name: 'Футбольный мяч Vega Star, размер 5',
                price: 339,
                img: first,
            },
            {
                id: 2,
                name: 'Футбольный мяч Vega Star, размер 5',
                price: 229,
                img: second,
            },
            {
                id: 3,
                name: 'Футбольный мяч Vega Star, размер 5',
                price: 119,
                img: third,
            },
            {
                id: 4,
                name: 'Футбольный мяч Vega Star, размер 5',
                price: 339,
                img: first,
            },
            {
                id: 5,
                name: 'Футбольный мяч Vega Star, размер 5',
                price: 229,
                img: second,
            },
            {
                id: 6,
                name: 'Футбольный мяч Vega Star, размер 5',
                price: 119,
                img: third,
            },
            {
                id: 7,
                name: 'Футбольный мяч Vega Star, размер 5',
                price: 339,
                img: first,
            },
            {
                id: 8,
                name: 'Футбольный мяч Vega Star, размер 5',
                price: 229,
                img: second,
            },
            {
                id: 9,
                name: 'Футбольный мяч Vega Star, размер 5',
                price: 119,
                img: third,
            },
        ]
    },
    {
        name: 'Все виды спорта',
        items: [
            {
                id: 10,
                name: 'Вратарские перчатки для футбола. Синий',
                price: 108,
                img: fourth,
            },
            {
                id: 11,
                name: 'Вратарские перчатки для футбола.',
                price: 128,
                img: fiveth,
            },
            {
                id: 12,
                name: 'Вратарские перчатки для футбола. Синий',
                price: 208,
                img: sixth,
            },
            {
                id: 13,
                name: 'Вратарские перчатки для футбола. Синий',
                price: 108,
                img: fourth,
            },
            {
                id: 14,
                name: 'Вратарские перчатки для футбола.',
                price: 128,
                img: fiveth,
            },
            {
                id: 15,
                name: 'Вратарские перчатки для футбола. Синий',
                price: 208,
                img: sixth,
            },
            {
                id: 16,
                name: 'Вратарские перчатки для футбола. Синий',
                price: 108,
                img: fourth,
            },
            {
                id: 17,
                name: 'Вратарские перчатки для футбола.',
                price: 128,
                img: fiveth,
            },
            {
                id: 18,
                name: 'Вратарские перчатки для футбола. Синий',
                price: 208,
                img: sixth,
            },
        ]
    }
]


const CategoryItem = () => {
    const { category } = useParams()
    const history = useHistory();

    return (
        <>
            <h1 className={styles.title}>{category}</h1>
            <div className={styles.products_container}>
                {data.filter((c) => c.name === category)[0].items.map((p) =>
                    <div key={p.id} onClick={() => history.push(`/market/${category}/${p.name}`)} className={styles.product_card}>
                        <img src={p.img} />
                        <div className={styles.product_name}>{p.name}</div>
                        <div className={styles.product_price}>{p.price} c</div>
                        <button className={styles.product_btn}>Купить</button>
                    </div>
                )}
            </div>
        </>
    )
}

export default CategoryItem;