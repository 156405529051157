import { SET_SCORE_LOADING } from "../actions/SetScore";


const initialState = {
	loading: false,
}


export const setScoreReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_SCORE_LOADING:
			return { ...state, loading: action.payload };
		default:
			return state
	}
}