import { GET_PLAYER_INFO, GET_PROFILE, LOGIN, SET_ALERT } from '.';
import { ApiUrl } from '../../config';
import { getAccessToken, httpReguest } from '../../utils/httpFunctions';
import { initialAlertState } from "../reducers/alert";

export const CHANGE_PLAYER_LOADER = 'CHANGE_PLAYER_LOADER';
export const SET_PLAYER_CLUB_HISTORY = 'SET_PLAYER_CLUB_HISTORY';



export const getPlayerClubHistory = (id) => async (dispatch) => {
	try {
		if (getAccessToken() == "") {
			dispatch({ type: LOGIN, payload: false })
			dispatch({ type: GET_PROFILE, payload: false })
			dispatch({ type: GET_PLAYER_INFO, payload: null })
		}
		dispatch({ type: SET_PLAYER_CLUB_HISTORY, payload: [] });
		dispatch({ type: CHANGE_PLAYER_LOADER, payload: true })

		const apiData = {
			playerId: id.toString(),
		}

		const apiConfig = {
			method: "POST",
			headers: {
				"Accept": "application/json",
			},
			body: JSON.stringify(apiData),
		}
		const response = await httpReguest(`${ApiUrl}client/player/history`, apiConfig)
		dispatch({ type: SET_PLAYER_CLUB_HISTORY, payload: response.payload.histories });

	} catch (e) {
		console.log(e)
		if (e.apiResponse.code !== 404) {
			dispatch({
				type: SET_ALERT,
				payload: { ...initialAlertState, open: true, message: e.apiResponse.message, type: "error", position: initialAlertState.position }
			}
			)
		}
	} finally {
		dispatch({ type: CHANGE_PLAYER_LOADER, payload: false });
	}
}