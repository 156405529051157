import React, {useContext} from 'react';
// Context API
// Material UI
import {Snackbar} from "@material-ui/core";
// Material UI Lab
import MuiAlert from "@material-ui/lab/Alert";
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { SET_ALERT } from '../store/actions';

const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const SnackbarAlert = ({user, show, onClose, ...props}) =>  {
    const dispatch = useDispatch()
    const alertState = useSelector((state) => state.alertReducer, shallowEqual) 
    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        dispatch({type: SET_ALERT, payload: {
            ...alertState,
            open: false,
         }})
    };

    return (
        <Snackbar
            open={alertState.open}
            autoHideDuration={1000}
            onClose={handleCloseSnackbar}
            anchorOrigin={{vertical: alertState.position.vertical, horizontal: alertState.position.horizontal}}
        >
            <Alert onClose={handleCloseSnackbar} severity={alertState.type}>
                {alertState.message}
            </Alert>
        </Snackbar>
    );
};

export default SnackbarAlert;