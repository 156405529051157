import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import p from './PlayerView.module.css'
import { Grid } from '@material-ui/core';
import playerIcon from '../../../../assets/playerIcon.jpg'
import { parseImagePath } from '../Players';
import { imgUrl } from '../../../../config';
import { phonePormat } from '../../../../utils/httpFunctions';
import PhoneHide from '../../../../utils/PhoneHide/PhoneHide';
import { useDispatch, useSelector } from 'react-redux';
import { getPlayerClubHistory } from '../../../../store/actions/PlayerHistory';
import PlayerHistoryCard from '../components/PlayerHistoryCard/PlayerHistoryCard';
import { ageFormat } from '../../../../utils/AgeFormat/ageFormat';

const styles = (theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing(2),
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500],
	},
});

const DialogTitle = withStyles(styles)((props) => {
	const { children, classes, onClose, ...other } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root} {...other}>
			<Typography variant="h6">{children}</Typography>
			{onClose ? (
				<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
					<CloseIcon />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
});

const DialogContent = withStyles((theme) => ({
	root: {
		padding: theme.spacing(2),
	},
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing(1),
	},
}))(MuiDialogActions);

const PlayerView = ({ show, onClose, playerItem }) => {
	const dispatch = useDispatch();
	const clubHistory = useSelector((state) => state.playersListReducer.clubHistory);

	useEffect(() => {
		if (show) {
			dispatch(getPlayerClubHistory(playerItem?.id))
		}
	}, [show])


	if (show) {


		return (
			<div>
				<Dialog maxWidth={"md"} fullWidth aria-labelledby="customized-dialog-title" open={show} className={p.container}>
					<DialogTitle id="customized-dialog-title" onClose={onClose} className={p.title}>
						Подробная информация
					</DialogTitle>
					<DialogContent dividers className={p.content}>
						<Grid container>
							<Grid item xs={4} md={4}>
								<div className={p.avatar}>
									{playerItem.imagePath == "" ? <img src={playerIcon} /> :
										<img src={`${imgUrl}${parseImagePath(playerItem.imagePath)}`} />
									}
									<div className={p.fullName}>
										{playerItem.name} {playerItem.surname}
									</div>
								</div>
							</Grid>
							<Grid item xs={8} md={8}>
								<div className={p.info}>
									<div className={p.row}>
										<div className={p.key}>Клуб:</div>
										<div className={p.value}>{playerItem.clubName == "" ? `-` : playerItem.clubName}</div>
									</div>
									<div className={p.row}>
										<div className={p.key}>Номер:</div>
										<div className={p.value}>{playerItem.number}</div>
									</div>
									<div className={p.row}>
										<div className={p.key}>Возраст:</div>
										<div className={p.value}>{playerItem.age} {ageFormat(playerItem.age)}</div>
									</div>
									<div className={p.row}>
										<div className={p.key}>Роль:</div>
										<div className={p.value}>{playerItem.isManager ? 'Капитан' : 'Игрок'}</div>
									</div>
									<div className={p.row}>
										<div className={p.key}>Позиция:</div>
										<div className={p.value}>{playerItem.position}</div>
									</div>

								</div>
							</Grid>
						</Grid>
						<Grid container>
							<Grid item xs={12}>
								{clubHistory?.length > 0 ?
									<>
										<div className={p.clubs_histroy_title}>
											История клубов игрока:
										</div>
										<div className={p.club_history_keys}>
											<div className={p.key_item}>Клуб</div>
											<div className={p.key_item}>Роль</div>
											<div className={p.key_item}>От</div>
											<div className={p.key_item}>До</div>
										</div>
										<div className={p.history_container}>
											{clubHistory?.map((club) =>
												<PlayerHistoryCard key={club.id} clubName={club?.clubName} position={club?.position} role={club?.role} startDate={club?.startDate} endDate={club?.endDate} />
											)}
										</div>
									</>
									: null
								}
							</Grid>
						</Grid>
					</DialogContent>
				</Dialog>
			</div>
		);
	}

	return <div></div>
}

export default PlayerView;