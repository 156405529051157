import React from 'react';
import styles from './PlayerHistoryCard.module.css';



const PlayerHistoryCard = ({ clubName, position, role, startDate, endDate }) => {
	return (
		<div className={styles.container}>
			<div className={styles.row}>
				<div className={styles.value}>{clubName.length > 12 ? clubName.slice(0,9) + '.' : clubName}</div>
			</div>
			<div className={styles.row}>
				<div className={styles.value}>{role}</div>
			</div>
			<div className={styles.row}>
				<div className={styles.value}>{startDate}</div>
			</div>
			<div className={styles.row}>
				<div className={styles.value}>{endDate}</div>
			</div>
		</div>
	)
}

export default PlayerHistoryCard