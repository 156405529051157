import { CircularProgress } from '@material-ui/core';
import React from 'react'
import l from './Loader.module.css'

const Loader = () => {
    return (
        <div className={l.cont}>
            <CircularProgress color="secondary" />
        </div>
    )
}

export default Loader;