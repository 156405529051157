import { ApiUrl } from "../../config";
import { Games16, Games8 } from '../../emptyResponseData';
import { getAccessToken, getMonth, httpReguest } from "../../utils/httpFunctions";
import { initialAlertState } from "../reducers/alert";

export const GET_GAMES = "GET_GAMES";
export const CHANGE_LOADER = "CHANGE_LOADER";
export const SET_PREMIRE = "SET_PREMIRE";
export const SET_GAME_DATE = "SET_GAME_DATE";
export const GET_PLAYERS = "GET_PLAYERS";
export const ERROR_PLAYER = "ERROR_PLAYER";
export const GET_CLUBS = "GET_CLUBS";
export const ERROR_CLUB = "ERROR_CLUB";
export const GET_STATUS = "GET_STATUS"
export const ERROR_STADIUM = "ERROR_STADIUM";
export const PAGINATION_PLAYER = "PAGINATION_PLAYER";
export const PAGINATION_CLUB = "PAGINATION_CLUB";
export const PLAYER_ID = "PLAYER_ID";
export const GET_STADIUM = "GET_STADIUM";
export const LOGIN = "LOGIN"
export const CHANGE_AVATAR_LOGO = "CHANGE_AVATAR_LOGO";
export const GET_PLAYER_INFO = "GET_PLAYER_INFO"
export const LOGIN_LOADER = "LOGIN_LOADER";
export const GET_PROFILE = "GET_PROFILE";
export const GET_CLUB_INFO = "GET_CLUB_INFO"
export const REDIRECT = "REDIRECT";
export const HAS_CLUB = "HAS_CLUB";
export const GET_CLUB_ID = "GET_CLUB_ID";
export const GET_INVITERS = "GET_INVITERS"
export const GET_REQUEST = "GET_REQUEST";
export const INVITERS_ERROR = "INVITERS_ERROR";
export const SET_ALERT = "SET_ALERT";
export const SET_INIT_DATE = "SET_INIT_DATE"
export const GET_GAME_BY_CLUB = "GET_GAME_BY_CLUB";
export const GET_CHAMPIONSHIP_LIST = "GET_CHAMPIONSHIP_LIST";
export const SELECT_CHAMPIONSHIP = "SELECT_CHAMPIONSHIP";
export const GET_CHAMPIONSHIP_CLUBS = "GET_CHAMPIONSHIP_CLUBS";
export const SET_TOSS = "SET_TOSS";

// schools

export const GET_SCHOOLS = "GET_SCHOOLS";
export const SCHOOLS_LOADER = "SCHOOLS_LOADER";

// services

export const GET_SERVICES = "GET_SERVICES";
export const SERVICES_LOADER = "SERVICES_LOADER";

// championship item
export const SET_CHAMPIONSHIP_ITEM_NAME = "SET_CHAMPIONSHIP_ITEM_NAME";

const getPagination = (num, rows) => {
	if (num % rows == 0) {
		return parseInt(num / rows) - 1
	}
	else {
		return (parseInt(num / rows))
	}
}


export const changeLoader = (bool) => {
	return {
		type: CHANGE_LOADER,
		payload: bool
	}
}

export const setGameDate = (date) => {
	return {
		type: SET_GAME_DATE,
		payload: date
	}
}

export const getClubs = () => {
	return async function (dispatch) {
		try {
			if (getAccessToken() == "") {
				dispatch({ type: LOGIN, payload: false })
				dispatch({ type: GET_PROFILE, payload: false })
				dispatch({ type: GET_PLAYER_INFO, payload: null })
			}

			const apiData = {
				id: "",
				club1: "",
				club2: "",
				gameDate: "",
				stadium: "",
				createdDate: "",
				statusId: "",
				rowsLimit: 10
			}

			const apiConfig = {
				method: "POST",
				headers: {
					"Accept": "application/json",
				},
				body: JSON.stringify(apiData),
			}

			dispatch(changeLoader(true));
			const response = await httpReguest(`${ApiUrl}client/games`, apiConfig)
			dispatch({ type: GET_GAMES, payload: response.payload.games })
			dispatch({ type: SET_PREMIRE, payload: response.payload.games[0] })

			let getDate = response.payload.games[0].gameDateFrom;

			let date = getMonth(getDate)
			dispatch({ type: SET_INIT_DATE, payload: getDate })
			dispatch(setGameDate(date))
			dispatch(changeLoader(false));
		}
		catch (e) {
			console.log(e)
			if (e.apiResponse.code !== 404) {
				dispatch({
					type: SET_ALERT,
					payload: { ...initialAlertState, open: true, message: e.apiResponse.message, type: "error", position: initialAlertState.position }
				}
				)
			}
			dispatch(changeLoader(false));
		}
	}
}


export const getPlayers = (name = "", phone = "", ageFrom = "", ageTo = "", hasClub = "", statusId, rowsOffset, rowsLimit = 15) => {
	return async function (dispatch) {
		try {
			if (getAccessToken() == "") {
				dispatch({ type: LOGIN, payload: false })
				dispatch({ type: GET_PROFILE, payload: false })
				dispatch({ type: GET_PLAYER_INFO, payload: null })
			}

			const apiData = {
				id: "",
				name: name,
				surname: "",
				phone: phone,
				statusId: "" + statusId,
				birthDateFrom: ageFrom,
				birthDateTo: ageTo,
				hasClub: "" + hasClub,
				rowsLimit: rowsLimit,
				rowsOffset: rowsOffset * rowsLimit,
			}

			const apiConfig = {
				method: "POST",
				headers: {
					"Accept": "application/json",
				},
				body: JSON.stringify(apiData),
			}
			dispatch(changeLoader(true));
			const response = await httpReguest(`${ApiUrl}client/players`, apiConfig)
			dispatch({ type: ERROR_PLAYER, payload: false });
			dispatch({ type: GET_PLAYERS, payload: response.payload.players })
			dispatch({ type: PAGINATION_PLAYER, payload: getPagination(response.payload.total, rowsLimit) })
			dispatch(changeLoader(false));
		}
		catch (e) {
			dispatch({ type: ERROR_PLAYER, payload: true });
			console.log(e)
			dispatch({ type: GET_PLAYERS, payload: [] })
			dispatch({ type: PAGINATION_PLAYER, payload: 0 })
			dispatch(changeLoader(false));
		}
	}
}

export const getClubsList = (id = "", name = "", phone = "", statusId = "", rowsOffset, rowsLimit = 15) => {
	return async function (dispatch) {
		try {
			if (getAccessToken() == "") {
				dispatch({ type: LOGIN, payload: false })
				dispatch({ type: GET_PROFILE, payload: false })
				dispatch({ type: GET_PLAYER_INFO, payload: null })
			}

			const apiData = {
				id: id,
				name: name,
				phone: phone,
				statusId: "" + statusId,
				rowsLimit: rowsLimit,
				rowsOffset: rowsLimit * rowsOffset
			}

			const apiConfig = {
				method: "POST",
				headers: {
					"Accept": "application/json",
				},
				body: JSON.stringify(apiData),
			}
			dispatch(changeLoader(true));
			const response = await httpReguest(`${ApiUrl}client/clubs`, apiConfig)
			dispatch({ type: ERROR_CLUB, payload: false });
			dispatch({ type: GET_CLUBS, payload: response.payload.clubs })
			dispatch({ type: PAGINATION_CLUB, payload: getPagination(response.payload.total, rowsLimit) })
			dispatch(changeLoader(false));
		}
		catch (e) {
			dispatch({ type: ERROR_CLUB, payload: true });
			console.log(e)
			dispatch({ type: GET_CLUBS, payload: [] })
			dispatch({ type: PAGINATION_CLUB, payload: 0 })
			dispatch(changeLoader(false));
			if (e.apiResponse.code !== 404) {
				dispatch({
					type: SET_ALERT,
					payload: { ...initialAlertState, open: true, message: e.apiResponse.message, type: "error", position: initialAlertState.position }
				}
				)
			}
		}
	}
}

export const getStates = () => {
	return async function (dispatch) {
		try {
			if (getAccessToken() == "") {
				dispatch({ type: LOGIN, payload: false })
				dispatch({ type: GET_PROFILE, payload: false })
				dispatch({ type: GET_PLAYER_INFO, payload: null })
			}

			dispatch(changeLoader(true));
			const apiConfig = {
				method: "GET",
				headers: {
					"Accept": "application/json",
				},
			}
			const response = await httpReguest(`${ApiUrl}status`, apiConfig)
			dispatch({ type: GET_STATUS, payload: response.payload })
			dispatch(changeLoader(false));
		}
		catch (e) {
			console.log(e)
			dispatch(changeLoader(false));
			if (e.apiResponse.code !== 404) {
				dispatch({
					type: SET_ALERT,
					payload: { ...initialAlertState, open: true, message: e.apiResponse.message, type: "error", position: initialAlertState.position }
				}
				)
			}
		}
	}
}


export const getPlayersById = (id) => {
	return async function (dispatch) {
		try {
			if (getAccessToken() == "") {
				dispatch({ type: LOGIN, payload: false })
				dispatch({ type: GET_PROFILE, payload: false })
				dispatch({ type: GET_PLAYER_INFO, payload: null })
			}

			const apiConfig = {
				method: "GET",
				headers: {
					"Accept": "application/json",
				},
			}
			const response = await httpReguest(`${ApiUrl}client/club/players/${id}`, apiConfig)
			dispatch({ type: PLAYER_ID, payload: response.payload.players })
		}
		catch (e) {
			console.log(e)
			if (e.apiResponse.code !== 404) {
				dispatch({
					type: SET_ALERT,
					payload: { ...initialAlertState, open: true, message: e.apiResponse.message, type: "error", position: initialAlertState.position }
				}
				)
			}
		}
	}
}

export const getStadiums = (id = "", name = "", city = "", address = "", phone1 = "", phone2 = "", rowsLimit = 10) => {
	return async function (dispatch) {
		try {
			if (getAccessToken() == "") {
				dispatch({ type: LOGIN, payload: false })
				dispatch({ type: GET_PROFILE, payload: false })
				dispatch({ type: GET_PLAYER_INFO, payload: null })
			}

			const apiData = {
				id: id,
				name: name,
				city: city,
				phone1: phone1,
				phone2: phone2,
				address: address,
				rowsLimit: rowsLimit,
			}

			const apiConfig = {
				method: "POST",
				headers: {
					"Accept": "application/json",
				},
				body: JSON.stringify(apiData),
			}
			dispatch(changeLoader(true));
			const response = await httpReguest(`${ApiUrl}stadiums`, apiConfig)
			dispatch({ type: ERROR_STADIUM, payload: false });
			dispatch({ type: GET_STADIUM, payload: response.payload.stadiums })
			dispatch(changeLoader(false));
		}
		catch (e) {
			dispatch({ type: ERROR_STADIUM, payload: true });
			console.log(e)
			dispatch({ type: GET_STADIUM, payload: [] })
			dispatch(changeLoader(false));
			if (e.apiResponse.code !== 404) {
				dispatch({
					type: SET_ALERT,
					payload: { ...initialAlertState, open: true, message: e.apiResponse.message, type: "error", position: initialAlertState.position }
				}
				)
			}
		}
	}
}

export const LogIn = (phone, password, close, setPhone, setPassword) => {
	return async function (dispatch) {
		try {
			if (getAccessToken() == "") {
				dispatch({ type: LOGIN, payload: false })
				dispatch({ type: GET_PROFILE, payload: false })
				dispatch({ type: GET_PLAYER_INFO, payload: null })
			}

			const apiData = {
				phone: phone,
				password: password,
			}

			const apiConfig = {
				method: "POST",
				headers: {
					"Accept": "application/json",
				},
				body: JSON.stringify(apiData),
			}
			dispatch({ type: LOGIN_LOADER, payload: true });
			const response = await httpReguest(`${ApiUrl}client/login`, apiConfig)
			localStorage.setItem("access-token", response.payload.token);
			dispatch({ type: GET_PLAYER_INFO, payload: response.payload })
			dispatch({ type: GET_CLUB_ID, payload: response.payload.clubId })
			dispatch({ type: CHANGE_AVATAR_LOGO, payload: response.payload.image })
			dispatch({ type: LOGIN, payload: true })
			dispatch({ type: LOGIN_LOADER, payload: false });
			if (response.payload.clubId) {
				dispatch({ type: HAS_CLUB, payload: true });
			}
			else {
				dispatch({ type: HAS_CLUB, payload: false });
			}

			close()
			setPhone("")
			setPassword("")

		}
		catch (e) {
			console.log(e)
			if (e.apiResponse.code !== 404) {
				dispatch({
					type: SET_ALERT,
					payload: { ...initialAlertState, open: true, message: e.apiResponse.message, type: "error", position: initialAlertState.position }
				}
				)
			}
			dispatch({ type: LOGIN, payload: false })
			dispatch({ type: LOGIN_LOADER, payload: false });
		}
	}
}

export const Profile = () => {
	return async function (dispatch) {
		try {
			dispatch({ type: LOGIN_LOADER, payload: true });
			if (getAccessToken() == "") {
				dispatch({ type: LOGIN, payload: false })
				dispatch({ type: GET_PROFILE, payload: false })
				dispatch({ type: GET_PLAYER_INFO, payload: null })

			}

			const apiConfig = {
				method: "GET",
				headers: {
					"Accept": "application/json",
				},
			}
			const response = await httpReguest(`${ApiUrl}client/player/profile`, apiConfig)
			dispatch({ type: GET_PLAYER_INFO, payload: response.payload })
			dispatch({ type: LOGIN, payload: true })
			dispatch({ type: LOGIN_LOADER, payload: false });
			dispatch({ type: GET_CLUB_ID, payload: response.payload.clubId })
			dispatch({ type: CHANGE_AVATAR_LOGO, payload: response.payload.playerImg })
			dispatch({ type: GET_PROFILE, payload: true })
			if (response.payload.clubId) {
				dispatch({ type: HAS_CLUB, payload: true });
			}
			else {
				dispatch({ type: HAS_CLUB, payload: false });
			}
		}
		catch (e) {
			if (e.apiResponse.code !== 404) {
				dispatch({
					type: SET_ALERT,
					payload: { ...initialAlertState, open: true, message: e.apiResponse.message, type: "error", position: initialAlertState.position }
				}
				)
			}
			console.log(e)
			dispatch({ type: LOGIN, payload: false })
			dispatch({ type: LOGIN_LOADER, payload: false });

		} finally {
			dispatch({ type: LOGIN_LOADER, payload: false });
		}
	}
}

export const Club = (id) => {
	return async function (dispatch) {
		try {
			if (getAccessToken() == "") {
				dispatch({ type: LOGIN, payload: false })
				dispatch({ type: GET_PROFILE, payload: false })
				dispatch({ type: GET_PLAYER_INFO, payload: null })
			}

			const apiConfig = {
				method: "GET",
				headers: {
					"Accept": "application/json",
				},
			}
			dispatch({ type: LOGIN_LOADER, payload: true });
			const response = await httpReguest(`${ApiUrl}client/club/profile/${id}`, apiConfig)
			dispatch({ type: GET_CLUB_INFO, payload: response.payload })
			dispatch({ type: HAS_CLUB, payload: true })
			dispatch({ type: LOGIN, payload: true })
			dispatch({ type: LOGIN_LOADER, payload: false });
		}
		catch (e) {
			if (e.apiResponse.code == 400) {
				dispatch({
					type: SET_ALERT,
					payload: { ...initialAlertState, open: true, message: e.apiResponse.message, type: "error", position: initialAlertState.position }
				}
				)
				dispatch({ type: LOGIN_LOADER, payload: false });
				dispatch({ type: HAS_CLUB, payload: false })
				return
			}
			console.log(e)
			dispatch({ type: LOGIN, payload: false })
			dispatch({ type: LOGIN_LOADER, payload: false });
		}
	}
}


export const Logout = () => {
	return async function (dispatch) {
		const result = window.confirm("Вы действительно хотите выйти из аккаунта?")

		if (result) {
			dispatch({ type: LOGIN, payload: false })
			dispatch({ type: GET_PROFILE, payload: false })
			dispatch({ type: GET_PLAYER_INFO, payload: null })
			dispatch({ type: GET_CLUB_INFO, payload: null })
			dispatch({ type: GET_INVITERS, payload: [] })
			dispatch({ type: GET_CLUB_ID, payload: 0 })
			localStorage.removeItem("access-token")
			return
		}
		return

	}
}


export const SignUp = (redirect = false, name, surname, phone, password, position, birthDate, otpCode = '', showOtpModal = () => { }) => {
	return async function (dispatch) {
		try {
			if (getAccessToken() == "") {
				dispatch({ type: LOGIN, payload: false })
				dispatch({ type: GET_PROFILE, payload: false })
				dispatch({ type: GET_PLAYER_INFO, payload: null })
			}
			const getYear = birthDate.toString().split(" ")[3]
			const getMonth = birthDate.getMonth() + 1
			const getDay = birthDate.getDate()

			const apiData = {
				name: name,
				surname: surname,
				phone: phone,
				password: password,
				birthDate: `${getYear}.${getMonth}.${getDay}`,
				position: position,
				otp: otpCode,
			}

			const apiConfig = {
				method: "POST",
				headers: {
					"Accept": "application/json",
				},
				body: JSON.stringify(apiData)
			}
			dispatch({ type: LOGIN_LOADER, payload: true })
			const response = await httpReguest(`${ApiUrl}client/player/create`, apiConfig)
			showOtpModal();
			if (redirect) {
				dispatch({
					type: SET_ALERT,
					payload: { ...initialAlertState, open: true, message: "Вы успешно создали игрока.", type: "success", position: initialAlertState.position }
				}
				)
				dispatch({ type: REDIRECT, payload: true })
			}
			dispatch({ type: LOGIN_LOADER, payload: false })
		}
		catch (e) {
			if (e.apiResponse.code !== 404) {
				dispatch({
					type: SET_ALERT,
					payload: { ...initialAlertState, open: true, message: e.apiResponse.message, type: "error", position: initialAlertState.position }
				}
				)
			}
			console.log(e)
			dispatch({ type: LOGIN_LOADER, payload: false })
		}
	}
}


export const createClub = (name, phone, manager, city) => {
	return async function (dispatch) {
		try {
			if (getAccessToken() == "") {
				dispatch({ type: LOGIN, payload: false })
				dispatch({ type: GET_PROFILE, payload: false })
				dispatch({ type: GET_PLAYER_INFO, payload: null })
			}

			const apiData = {
				name: name,
				phone: phone,
				manager: manager.toString(),
				city: city,
			}

			const apiConfig = {
				method: "POST",
				headers: {
					"Accept": "application/json",
				},
				body: JSON.stringify(apiData)
			}
			dispatch({ type: LOGIN_LOADER, payload: true })
			const response = await httpReguest(`${ApiUrl}client/club/create`, apiConfig)
			dispatch({
				type: SET_ALERT,
				payload: { ...initialAlertState, open: true, message: "Вы успешно создали команду.", type: "success", position: initialAlertState.position }
			}
			)
			dispatch({ type: LOGIN_LOADER, payload: false })
			dispatch({ type: GET_CLUB_ID, payload: response.payload.id })
			dispatch({ type: REDIRECT, payload: true })
		}
		catch (e) {
			if (e.apiResponse.code !== 404) {
				dispatch({
					type: SET_ALERT,
					payload: { ...initialAlertState, open: true, message: e.apiResponse.message, type: "error", position: initialAlertState.position }
				}
				)
			}
			console.log(e)
			dispatch({ type: LOGIN_LOADER, payload: false })
		}
	}
}

export const PlayerChangePassword = (id, oldPassword, newPassword, onClose) => {
	return async function (dispatch) {
		try {
			if (getAccessToken() == "") {
				dispatch({ type: LOGIN, payload: false })
				dispatch({ type: GET_PROFILE, payload: false })
				dispatch({ type: GET_PLAYER_INFO, payload: null })
			}

			const apiData = {
				id: id.toString(),
				oldPassword: oldPassword,
				newPassword: newPassword,
			}

			const apiConfig = {
				method: "POST",
				headers: {
					"Accept": "application/json",
				},
				body: JSON.stringify(apiData)
			}
			dispatch({ type: LOGIN_LOADER, payload: true })
			const response = await httpReguest(`${ApiUrl}client/player/password`, apiConfig)
			dispatch({
				type: SET_ALERT,
				payload: { ...initialAlertState, open: true, message: "Вы успешно изменили пароль.", type: "success", position: initialAlertState.position }
			}
			)
			dispatch({ type: LOGIN_LOADER, payload: false })
			onClose()
		}
		catch (e) {
			dispatch({
				type: SET_ALERT,
				payload: { ...initialAlertState, open: true, message: e.apiResponse.message, type: "error", position: initialAlertState.position }
			}
			)
			console.log(e)
			dispatch({ type: LOGIN_LOADER, payload: false })
		}
	}
}


export const changeImageProfile = (id, file, onClose) => {
	return async function (dispatch) {
		try {
			if (getAccessToken() == "") {
				dispatch({ type: LOGIN, payload: false })
				dispatch({ type: GET_PROFILE, payload: false })
				dispatch({ type: GET_PLAYER_INFO, payload: null })
			}

			const formData = new FormData()
			formData.append("playerId", id.toString())
			formData.append("image", file)

			const apiConfig = {
				method: "POST",
				body: formData,
				headers: {},
			}
			dispatch({ type: LOGIN_LOADER, payload: true })
			const response = await httpReguest(`${ApiUrl}client/player/picture`, apiConfig)
			dispatch({ type: LOGIN_LOADER, payload: false })
			onClose()
			dispatch(Profile())
		}
		catch (e) {
			console.log(e)
			if (e.apiResponse.code !== 404) {
				dispatch({
					type: SET_ALERT,
					payload: { ...initialAlertState, open: true, message: e.apiResponse.message, type: "error", position: initialAlertState.position }
				}
				)
			}
			dispatch({ type: LOGIN_LOADER, payload: false })
		}
	}
}


export const editProfile = (id, name, surname, birthDate, onClose, defaultValue) => {
	return async function (dispatch) {
		try {
			if (getAccessToken() == "") {
				dispatch({ type: LOGIN, payload: false })
				dispatch({ type: GET_PROFILE, payload: false })
				dispatch({ type: GET_PLAYER_INFO, payload: null })
			}

			const checkName = defaultValue.name === name ? `` : name;
			const checkSurname = defaultValue.surname === surname ? `` : surname;
			const checkBirthDate = defaultValue.birthDate === birthDate ? `` : birthDate


			const apiData = {
				id: id.toString(),
				name: checkName,
				surname: checkSurname,
				birthDate: checkBirthDate ? `${checkBirthDate.toString().split(" ")[3]}.${checkBirthDate.getMonth() + 1}.${checkBirthDate.getDate()}` : ``
			}

			const apiConfig = {
				method: "POST",
				headers: {
					"Accept": "application/json",
				},
				body: JSON.stringify(apiData)
			}
			dispatch({ type: CHANGE_LOADER, payload: true })
			const response = await httpReguest(`${ApiUrl}client/player/update`, apiConfig)
			dispatch({
				type: SET_ALERT,
				payload: { ...initialAlertState, open: true, message: "Вы успешно изменили данные профиля.", type: "success", position: initialAlertState.position }
			}
			)
			dispatch({ type: CHANGE_LOADER, payload: false })
			onClose()
			dispatch(Profile())
		}
		catch (e) {
			console.log(e)
			if (e.apiResponse.code !== 404) {
				dispatch({
					type: SET_ALERT,
					payload: { ...initialAlertState, open: true, message: e.apiResponse.message, type: "error", position: initialAlertState.position }
				}
				)
			}
			dispatch({ type: CHANGE_LOADER, payload: false })
		}
	}
}


export const changeImageClub = (clubId, file, onClose) => {
	return async function (dispatch) {
		try {
			if (getAccessToken() == "") {
				dispatch({ type: LOGIN, payload: false })
				dispatch({ type: GET_PROFILE, payload: false })
				dispatch({ type: GET_PLAYER_INFO, payload: null })
			}

			const formData = new FormData()
			formData.append("clubId", clubId.toString())
			formData.append("image", file)

			const apiConfig = {
				method: "POST",
				body: formData,
				headers: {},
			}
			dispatch({ type: LOGIN_LOADER, payload: true })
			const response = await httpReguest(`${ApiUrl}client/club/picture`, apiConfig)
			dispatch({
				type: SET_ALERT,
				payload: { ...initialAlertState, open: true, message: "Вы успешно изменили фото команды.", type: "success", position: initialAlertState.position }
			}
			)
			dispatch({ type: LOGIN_LOADER, payload: false })
			onClose()
			dispatch(Club(clubId))
		}
		catch (e) {
			console.log(e)
			if (e.apiResponse.code !== 404) {
				dispatch({
					type: SET_ALERT,
					payload: { ...initialAlertState, open: true, message: e.apiResponse.message, type: "error", position: initialAlertState.position }
				}
				)
			}
			dispatch({ type: LOGIN_LOADER, payload: false })
		}
	}
}


export const editClub = (id, name, phone, onClose, defaultValue) => {
	return async function (dispatch) {
		try {
			if (getAccessToken() == "") {
				dispatch({ type: LOGIN, payload: false })
				dispatch({ type: GET_PROFILE, payload: false })
				dispatch({ type: GET_PLAYER_INFO, payload: null })
			}

			const checkName = defaultValue.name === name ? `` : name;
			const checkPhone = defaultValue.phone === phone ? `` : phone;

			const apiData = {
				id: id.toString(),
				name: checkName,
				phone: checkPhone,
				city: defaultValue.city,
			}

			const apiConfig = {
				method: "POST",
				headers: {
					"Accept": "application/json",
				},
				body: JSON.stringify(apiData)
			}
			dispatch({ type: CHANGE_LOADER, payload: true })
			const response = await httpReguest(`${ApiUrl}client/club/update`, apiConfig)
			dispatch({
				type: SET_ALERT,
				payload: { ...initialAlertState, open: true, message: "Вы успешно изменили данные команды.", type: "success", position: initialAlertState.position }
			}
			)
			dispatch({ type: CHANGE_LOADER, payload: false })
			onClose()
			dispatch(Club(id))
		}
		catch (e) {
			console.log(e)
			if (e.apiResponse.code !== 404) {
				dispatch({
					type: SET_ALERT,
					payload: { ...initialAlertState, open: true, message: e.apiResponse.message, type: "error", position: initialAlertState.position }
				}
				)
			}
			dispatch({ type: CHANGE_LOADER, payload: false })
		}
	}
}



export const deleteProfileImage = (id) => {
	return async function (dispatch) {
		const result = window.confirm("Вы действительно хотите удалить фото профиля?")

		if (result) {
			try {
				if (getAccessToken() == "") {
					dispatch({ type: LOGIN, payload: false })
					dispatch({ type: GET_PROFILE, payload: false })
					dispatch({ type: GET_PLAYER_INFO, payload: null })
				}

				const formData = new FormData()
				formData.append("playerId", id.toString())

				const apiConfig = {
					method: "DELETE",
					body: formData,
					headers: {},
				}
				dispatch({ type: LOGIN_LOADER, payload: true })
				const response = await httpReguest(`${ApiUrl}client/player/picture`, apiConfig)
				dispatch({ type: LOGIN_LOADER, payload: false })
				dispatch(Profile())
			}
			catch (e) {
				console.log(e)
				if (e.apiResponse.code !== 404) {
					dispatch({
						type: SET_ALERT,
						payload: { ...initialAlertState, open: true, message: e.apiResponse.message, type: "error", position: initialAlertState.position }
					}
					)
				}
				dispatch({ type: LOGIN_LOADER, payload: false })
			}
		}
	}
}

export const deleteClubImage = (id) => {
	return async function (dispatch) {
		const result = window.confirm("Вы действительно хотите удалить фото команды?")

		if (result) {
			try {
				if (getAccessToken() == "") {
					dispatch({ type: LOGIN, payload: false })
					dispatch({ type: GET_PROFILE, payload: false })
					dispatch({ type: GET_PLAYER_INFO, payload: null })
				}

				const formData = new FormData()
				formData.append("clubId", id.toString())

				const apiConfig = {
					method: "DELETE",
					body: formData,
					headers: {},
				}
				dispatch({ type: LOGIN_LOADER, payload: true })
				const response = await httpReguest(`${ApiUrl}client/club/picture`, apiConfig)
				dispatch({ type: LOGIN_LOADER, payload: false })
				dispatch(Club(id))
			}
			catch (e) {
				console.log(e)
				if (e.apiResponse.code !== 404) {
					dispatch({
						type: SET_ALERT,
						payload: { ...initialAlertState, open: true, message: e.apiResponse.message, type: "error", position: initialAlertState.position }
					}
					)
				}
				dispatch({ type: LOGIN_LOADER, payload: false })
			}
		}
	}
}

export const invitePlayer = (playerId, clubId, inviter, number, closeFn = () => { }) => {
	return async function (dispatch) {
		try {
			if (getAccessToken() == "") {
				dispatch({ type: LOGIN, payload: false })
				dispatch({ type: GET_PROFILE, payload: false })
				dispatch({ type: GET_PLAYER_INFO, payload: null })
			}

			const apiData = {
				clubId: clubId.toString(),
				playerId: playerId.toString(),
				inviter: inviter,
				number: +number,
			}

			const apiConfig = {
				method: "POST",
				headers: {
					"Accept": "application/json",
				},
				body: JSON.stringify(apiData)
			}
			dispatch({ type: CHANGE_LOADER, payload: true })
			const response = await httpReguest(`${ApiUrl}client/club/player/request`, apiConfig)
			dispatch({
				type: SET_ALERT,
				payload: { ...initialAlertState, open: true, message: "Заявка отправлена!", type: "success", position: initialAlertState.position }
			}
			)
			closeFn();
			dispatch({ type: CHANGE_LOADER, payload: false })
		}
		catch (e) {
			console.log(e)
			if (e.apiResponse.code !== 404) {
				dispatch({
					type: SET_ALERT,
					payload: { ...initialAlertState, open: true, message: e.apiResponse.message, type: "error", position: initialAlertState.position }
				}
				)
			}
			dispatch({ type: CHANGE_LOADER, payload: false })
		}
	}
}

export const inviteClub = (playerId, clubId) => {
	return async function (dispatch) {
		const result = window.confirm("Вы действительно хотите стать участником команды?")
		if (result) {
			try {
				if (getAccessToken() == "") {
					dispatch({ type: LOGIN, payload: false })
					dispatch({ type: GET_PROFILE, payload: false })
					dispatch({ type: GET_PLAYER_INFO, payload: null })
				}

				const apiData = {
					clubId: clubId.toString(),
					playerId: playerId.toString(),
					inviter: "player",
				}

				const apiConfig = {
					method: "POST",
					headers: {
						"Accept": "application/json",
					},
					body: JSON.stringify(apiData)
				}
				dispatch({ type: CHANGE_LOADER, payload: true })
				const response = await httpReguest(`${ApiUrl}client/club/player/request`, apiConfig)
				dispatch({
					type: SET_ALERT,
					payload: { ...initialAlertState, open: true, message: "Заявка отправлена!", type: "success", position: initialAlertState.position }
				}
				)
				dispatch({ type: CHANGE_LOADER, payload: false })
			}
			catch (e) {
				console.log(e)
				if (e.apiResponse.code !== 404) {
					dispatch({
						type: SET_ALERT,
						payload: { ...initialAlertState, open: true, message: e.apiResponse.message, type: "error", position: initialAlertState.position }
					}
					)
				}
				dispatch({ type: CHANGE_LOADER, payload: false })
			}
		}
	}
}

export const getInvitesReceived = (playerId = "", clubId = "") => {
	return async function (dispatch) {
		try {
			if (getAccessToken() == "") {
				dispatch({ type: LOGIN, payload: false })
				dispatch({ type: GET_PROFILE, payload: false })
				dispatch({ type: GET_PLAYER_INFO, payload: null })
			}


			const apiData = {
				clubId: clubId.toString(),
				playerId: playerId.toString(),
			}


			const apiConfig = {
				method: "POST",
				headers: {
					"Accept": "application/json",
				},
				body: JSON.stringify(apiData)
			}
			dispatch({ type: CHANGE_LOADER, payload: true })
			const response = await httpReguest(`${ApiUrl}client/invitations`, apiConfig)
			dispatch({ type: GET_INVITERS, payload: response.payload.invites })
			dispatch({ type: CHANGE_LOADER, payload: false })
		}
		catch (e) {
			console.log(e)
			dispatch({ type: CHANGE_LOADER, payload: false })
			dispatch({ type: INVITERS_ERROR, payload: true })
			dispatch({ type: GET_INVITERS, payload: [] })
			if (e.apiResponse.code !== 404) {
				dispatch({
					type: SET_ALERT,
					payload: { ...initialAlertState, open: true, message: e.apiResponse.message, type: "error", position: initialAlertState.position }
				}
				)
			}
		}


	}
}


export const getInvitesSent = (playerId = "", clubId = "") => {
	return async function (dispatch) {
		try {
			if (getAccessToken() == "") {
				dispatch({ type: LOGIN, payload: false })
				dispatch({ type: GET_PROFILE, payload: false })
				dispatch({ type: GET_PLAYER_INFO, payload: null })
			}


			const apiData = {
				clubId: clubId.toString(),
				playerId: playerId.toString(),
			}


			const apiConfig = {
				method: "POST",
				headers: {
					"Accept": "application/json",
				},
				body: JSON.stringify(apiData)
			}
			dispatch({ type: CHANGE_LOADER, payload: true })
			const response = await httpReguest(`${ApiUrl}client/requests`, apiConfig)
			dispatch({ type: GET_REQUEST, payload: response.payload.invites })
			dispatch({ type: CHANGE_LOADER, payload: false })
		}
		catch (e) {
			console.log(e)
			dispatch({ type: CHANGE_LOADER, payload: false })
			dispatch({ type: INVITERS_ERROR, payload: true })
			dispatch({ type: GET_REQUEST, payload: [] })
			if (e.apiResponse.code !== 404) {
				dispatch({
					type: SET_ALERT,
					payload: { ...initialAlertState, open: true, message: e.apiResponse.message, type: "error", position: initialAlertState.position }
				}
				)
			}
		}
	}
}


export const deletePlayer = (clubId, playerId) => {
	return async function (dispatch) {
		const result = window.confirm("Вы действительно хотите удалить игрока?")

		if (result) {
			try {
				if (getAccessToken() == "") {
					dispatch({ type: LOGIN, payload: false })
					dispatch({ type: GET_PROFILE, payload: false })
					dispatch({ type: GET_PLAYER_INFO, payload: null })
				}


				const apiData = {
					clubId: clubId.toString(),
					playerId: playerId.toString(),
				}


				const apiConfig = {
					method: "POST",
					headers: {
						"Accept": "application/json",
					},
					body: JSON.stringify(apiData)
				}
				dispatch({ type: CHANGE_LOADER, payload: true })
				const response = await httpReguest(`${ApiUrl}client/club/player/leave`, apiConfig)
				dispatch(getPlayersById(clubId))
				dispatch({ type: CHANGE_LOADER, payload: false })
			}
			catch (e) {
				if (e.apiResponse.code !== 404) {
					dispatch({
						type: SET_ALERT,
						payload: { ...initialAlertState, open: true, message: e.apiResponse.message, type: "error", position: initialAlertState.position }
					}
					)
				}
				console.log(e)
				dispatch({ type: CHANGE_LOADER, payload: false })
			}
		}
	}
}

export const getGameInvites = (id) => {
	return async function (dispatch) {
		try {
			if (getAccessToken() == "") {
				dispatch({ type: LOGIN, payload: false })
				dispatch({ type: GET_PROFILE, payload: false })
				dispatch({ type: GET_PLAYER_INFO, payload: null })
			}


			const apiConfig = {
				method: "GET",
				headers: {
					"Accept": "application/json",
				},
			}
			dispatch({ type: CHANGE_LOADER, payload: true })
			const response = await httpReguest(`${ApiUrl}client/game/invitations/${id}`, apiConfig)
			dispatch({ type: CHANGE_LOADER, payload: false })
			dispatch({ type: GET_INVITERS, payload: response.payload.invites })
		}
		catch (e) {
			console.log(e)
			dispatch({ type: CHANGE_LOADER, payload: false })
			dispatch({ type: INVITERS_ERROR, payload: true })
			dispatch({ type: GET_INVITERS, payload: [] })
			if (e.apiResponse.code !== 404) {
				dispatch({
					type: SET_ALERT,
					payload: { ...initialAlertState, open: true, message: e.apiResponse.message, type: "error", position: initialAlertState.position }
				}
				)
			}
		}
	}
}


export const getGameRequests = (id) => {
	return async function (dispatch) {
		try {
			if (getAccessToken() == "") {
				dispatch({ type: LOGIN, payload: false })
				dispatch({ type: GET_PROFILE, payload: false })
				dispatch({ type: GET_PLAYER_INFO, payload: null })
			}


			const apiConfig = {
				method: "GET",
				headers: {
					"Accept": "application/json",
				},
			}
			dispatch({ type: CHANGE_LOADER, payload: true })
			const response = await httpReguest(`${ApiUrl}client/game/requests/${id}`, apiConfig)
			dispatch({ type: CHANGE_LOADER, payload: false })
			dispatch({ type: GET_REQUEST, payload: response.payload.invites })
		}
		catch (e) {
			console.log(e)
			dispatch({ type: CHANGE_LOADER, payload: false })
			dispatch({ type: INVITERS_ERROR, payload: true })
			dispatch({ type: GET_REQUEST, payload: [] })
			if (e.apiResponse.code !== 404) {
				dispatch({
					type: SET_ALERT,
					payload: { ...initialAlertState, open: true, message: e.apiResponse.message, type: "error", position: initialAlertState.position }
				}
				)
			}
		}
	}
}


export const requestGame = (inviter, invitee, timeFrom, timeTo, stadium, onClose) => {
	return async function (dispatch) {
		try {
			if (getAccessToken() == "") {
				dispatch({ type: LOGIN, payload: false })
				dispatch({ type: GET_PROFILE, payload: false })
				dispatch({ type: GET_PLAYER_INFO, payload: null })
			}

			const getYear = timeFrom.getFullYear()
			let getMonth = timeFrom.getMonth() + 1
			getMonth = getMonth < 10 ? ("0" + getMonth) : getMonth
			let getDay = timeFrom.getDate()
			getDay = getDay < 10 ? ("0" + getDay) : getDay
			let getHour = timeFrom.getHours()
			getHour = getHour < 10 ? "0" + getHour : getHour
			let getMinute = timeFrom.getMinutes()
			getMinute = getMinute < 10 ? "0" + getMinute : getMinute
			let getSecond = timeFrom.getSeconds()
			getSecond = getSecond < 10 ? "0" + getSecond : getSecond

			const apiData = {
				inviter: inviter,
				invitee: invitee,
				timeFrom: `${getYear}-${getMonth}-${getDay} ${getHour}:${getMinute}:${getSecond}`,
				duration: +timeTo,
				stadium: stadium,
			}

			const apiConfig = {
				method: "POST",
				headers: {
					"Accept": "application/json",
				},
				body: JSON.stringify(apiData)
			}
			dispatch({ type: CHANGE_LOADER, payload: true })
			const response = await httpReguest(`${ApiUrl}client/game/request`, apiConfig)
			dispatch({ type: CHANGE_LOADER, payload: false })
			dispatch({
				type: SET_ALERT,
				payload: { ...initialAlertState, open: true, message: "Вы успешно бросили вызов!", type: "success", position: initialAlertState.position }
			}
			)
			onClose()
		}
		catch (e) {
			console.log(e)
			if (e.apiResponse.code !== 404) {
				dispatch({
					type: SET_ALERT,
					payload: { ...initialAlertState, open: true, message: e.apiResponse.message, type: "error", position: initialAlertState.position }
				}
				)
			}
			dispatch({ type: CHANGE_LOADER, payload: false })
		}
	}
}


export const gameAccept = (id, type, clubId) => {
	return async function (dispatch) {
		try {
			if (getAccessToken() == "") {
				dispatch({ type: LOGIN, payload: false })
				dispatch({ type: GET_PROFILE, payload: false })
				dispatch({ type: GET_PLAYER_INFO, payload: null })
			}


			const apiConfig = {
				method: "GET",
				headers: {
					"Accept": "application/json",
				},
			}
			dispatch({ type: CHANGE_LOADER, payload: true })
			const response = await httpReguest(`${ApiUrl}client/game/accept/${id}`, apiConfig)
			type ? dispatch(getGameInvites(clubId)) : dispatch(getGameRequests(clubId))
		}
		catch (e) {
			console.log(e)
			dispatch({ type: CHANGE_LOADER, payload: false })
			if (e.apiResponse.code !== 404) {
				dispatch({
					type: SET_ALERT,
					payload: { ...initialAlertState, open: true, message: e.apiResponse.message, type: "error", position: initialAlertState.position }
				}
				)
			}
		}
	}
}

export const gameReject = (id, type, clubId) => {
	return async function (dispatch) {
		try {
			if (getAccessToken() == "") {
				dispatch({ type: LOGIN, payload: false })
				dispatch({ type: GET_PROFILE, payload: false })
				dispatch({ type: GET_PLAYER_INFO, payload: null })
			}


			const apiConfig = {
				method: "GET",
				headers: {
					"Accept": "application/json",
				},
			}
			dispatch({ type: CHANGE_LOADER, payload: true })
			const response = await httpReguest(`${ApiUrl}client/game/reject/${id}`, apiConfig)
			type ? dispatch(getGameInvites(clubId)) : dispatch(getGameRequests(clubId))
		}
		catch (e) {
			console.log(e)
			dispatch({ type: CHANGE_LOADER, payload: false })
			if (e.apiResponse.code !== 404) {
				dispatch({
					type: SET_ALERT,
					payload: { ...initialAlertState, open: true, message: e.apiResponse.message, type: "error", position: initialAlertState.position }
				}
				)
			}
		}
	}
}


export const acceptInvitationPlayer = (playerId, clubId) => {
	return async function (dispatch) {
		try {
			if (getAccessToken() == "") {
				dispatch({ type: LOGIN, payload: false })
				dispatch({ type: GET_PROFILE, payload: false })
				dispatch({ type: GET_PLAYER_INFO, payload: null })
			}


			const apiData = {
				clubId: clubId?.toString(),
				playerId: playerId?.toString(),
				inviter: "club",
			}


			const apiConfig = {
				method: "POST",
				headers: {
					"Accept": "application/json",
				},
				body: JSON.stringify(apiData)
			}
			dispatch({ type: CHANGE_LOADER, payload: true })
			const response = await httpReguest(`${ApiUrl}client/club/player/accept`, apiConfig)
			dispatch({ type: GET_CLUB_ID, payload: clubId })
			dispatch(getInvitationsListPlayer(true, playerId))
		}
		catch (e) {
			console.log(e)
			dispatch({ type: CHANGE_LOADER, payload: false })
			if (e.apiResponse.code !== 404) {
				dispatch({
					type: SET_ALERT,
					payload: { ...initialAlertState, open: true, message: e.apiResponse.message, type: "error", position: initialAlertState.position }
				}
				)
			}
		}
	}
}


export const rejectInvitationPlayer = (playerId, clubId, fn) => {
	return async function (dispatch) {
		try {
			if (getAccessToken() == "") {
				dispatch({ type: LOGIN, payload: false })
				dispatch({ type: GET_PROFILE, payload: false })
				dispatch({ type: GET_PLAYER_INFO, payload: null })
			}


			const apiData = {
				clubId: clubId?.toString(),
				playerId: playerId?.toString(),
			}


			const apiConfig = {
				method: "DELETE",
				headers: {
					"Accept": "application/json",
				},
				body: JSON.stringify(apiData)
			}
			dispatch({ type: CHANGE_LOADER, payload: true })
			const response = await httpReguest(`${ApiUrl}client/club/player/reject`, apiConfig)
			await dispatch(getInvitationsListPlayer(fn, playerId))
		}
		catch (e) {
			console.log(e)
			dispatch({ type: CHANGE_LOADER, payload: false })
			if (e.apiResponse.code !== 404) {
				dispatch({
					type: SET_ALERT,
					payload: { ...initialAlertState, open: true, message: e.apiResponse.message, type: "error", position: initialAlertState.position }
				}
				)
			}
		}
	}
}


export const getInvitationsListPlayer = (fn, playerId) => {
	return async function (dispatch) {
		try {
			if (getAccessToken() == "") {
				dispatch({ type: LOGIN, payload: false })
				dispatch({ type: GET_PROFILE, payload: false })
				dispatch({ type: GET_PLAYER_INFO, payload: null })
			}
			const newApiConfig = {
				method: "POST",
				headers: {
					"Accept": "application/json",
				},
				body: JSON.stringify({ playerId: playerId.toString(), clubId: "" })
			}
			if (fn) {
				const lastResponse = await httpReguest(`${ApiUrl}client/invitations`, newApiConfig)
				dispatch({ type: GET_INVITERS, payload: lastResponse.payload.invites })
				dispatch({ type: CHANGE_LOADER, payload: false })
				return
			}
			if (!fn) {
				const lastResponse = await httpReguest(`${ApiUrl}requests`, newApiConfig)
				dispatch({ type: GET_REQUEST, payload: lastResponse.payload.invites })
				dispatch({ type: CHANGE_LOADER, payload: false })
			}
		}
		catch (e) {
			console.log(e)
			dispatch({ type: CHANGE_LOADER, payload: false })
			if (e.apiResponse.code !== 404) {
				dispatch({
					type: SET_ALERT,
					payload: { ...initialAlertState, open: true, message: e.apiResponse.message, type: "error", position: initialAlertState.position }
				}
				)
			}
			if (fn) {
				dispatch({ type: INVITERS_ERROR, payload: true })
				dispatch({ type: GET_INVITERS, payload: [] })
				return
			}
			if (!fn) {
				dispatch({ type: INVITERS_ERROR, payload: true })
				dispatch({ type: GET_REQUEST, payload: [] })
				return
			}
		}
	}
}


export const acceptInvitationClub = (playerId, clubId) => {
	return async function (dispatch) {
		try {
			if (getAccessToken() == "") {
				dispatch({ type: LOGIN, payload: false })
				dispatch({ type: GET_PROFILE, payload: false })
				dispatch({ type: GET_PLAYER_INFO, payload: null })
			}


			const apiData = {
				clubId: clubId?.toString(),
				playerId: playerId?.toString(),
				inviter: "player",
			}


			const apiConfig = {
				method: "POST",
				headers: {
					"Accept": "application/json",
				},
				body: JSON.stringify(apiData)
			}
			dispatch({ type: CHANGE_LOADER, payload: true })
			const response = await httpReguest(`${ApiUrl}client/club/player/accept`, apiConfig)
			dispatch({ type: GET_CLUB_ID, payload: clubId })
			dispatch(getInvitationsListClub(true, clubId))
		}
		catch (e) {
			console.log(e)
			dispatch({ type: CHANGE_LOADER, payload: false })
			if (e.apiResponse.code !== 404) {
				dispatch({
					type: SET_ALERT,
					payload: { ...initialAlertState, open: true, message: e.apiResponse.message, type: "error", position: initialAlertState.position }
				}
				)
			}
		}
	}
}

export const getInvitationsListClub = (fn, clubId) => {
	return async function (dispatch) {
		try {
			if (getAccessToken() == "") {
				dispatch({ type: LOGIN, payload: false })
				dispatch({ type: GET_PROFILE, payload: false })
				dispatch({ type: GET_PLAYER_INFO, payload: null })
			}
			const newApiConfig = {
				method: "POST",
				headers: {
					"Accept": "application/json",
				},
				body: JSON.stringify({ playerId: "", clubId: clubId.toString() })
			}
			if (fn) {
				const lastResponse = await httpReguest(`${ApiUrl}client/invitations`, newApiConfig)
				dispatch({ type: GET_INVITERS, payload: lastResponse.payload.invites })
				dispatch({ type: CHANGE_LOADER, payload: false })
				return
			}
			if (!fn) {
				const lastResponse = await httpReguest(`${ApiUrl}client/requests`, newApiConfig)
				dispatch({ type: GET_REQUEST, payload: lastResponse.payload.invites })
				dispatch({ type: CHANGE_LOADER, payload: false })
			}
		}
		catch (e) {
			console.log(e)
			dispatch({ type: CHANGE_LOADER, payload: false })
			if (e.apiResponse.code !== 404) {
				dispatch({
					type: SET_ALERT,
					payload: { ...initialAlertState, open: true, message: e.apiResponse.message, type: "error", position: initialAlertState.position }
				}
				)
			}
			if (fn) {
				dispatch({ type: INVITERS_ERROR, payload: true })
				dispatch({ type: GET_INVITERS, payload: [] })
				return
			}
			if (!fn) {
				dispatch({ type: INVITERS_ERROR, payload: true })
				dispatch({ type: GET_REQUEST, payload: [] })
				return
			}
		}
	}
}

export const rejectInvitationClub = (playerId, clubId, fn) => {
	return async function (dispatch) {
		try {
			if (getAccessToken() == "") {
				dispatch({ type: LOGIN, payload: false })
				dispatch({ type: GET_PROFILE, payload: false })
				dispatch({ type: GET_PLAYER_INFO, payload: null })
			}


			const apiData = {
				clubId: clubId?.toString(),
				playerId: playerId?.toString(),
			}


			const apiConfig = {
				method: "DELETE",
				headers: {
					"Accept": "application/json",
				},
				body: JSON.stringify(apiData)
			}
			dispatch({ type: CHANGE_LOADER, payload: true })
			const response = await httpReguest(`${ApiUrl}client/club/player/reject`, apiConfig)
			await dispatch(getInvitationsListClub(fn, clubId))
		}
		catch (e) {
			console.log(e)
			if (e.apiResponse.code !== 404) {
				dispatch({
					type: SET_ALERT,
					payload: { ...initialAlertState, open: true, message: e.apiResponse.message, type: "error", position: initialAlertState.position }
				}
				)
			}
			dispatch({ type: CHANGE_LOADER, payload: false })
		}
	}
}


export const getGamesByClubId = (clubId, rowsLimit = 10, rowsOffset = 0) => {
	return async function (dispatch) {
		try {
			if (getAccessToken() == "") {
				dispatch({ type: LOGIN, payload: false })
				dispatch({ type: GET_PROFILE, payload: false })
				dispatch({ type: GET_PLAYER_INFO, payload: null })
			}


			const apiData = {
				clubId: clubId?.toString(),
				rowsLimit: rowsLimit,
				rowsOffset: rowsOffset,
			}


			const apiConfig = {
				method: "POST",
				headers: {
					"Accept": "application/json",
				},
				body: JSON.stringify(apiData)
			}
			dispatch({ type: CHANGE_LOADER, payload: true })
			const response = await httpReguest(`${ApiUrl}client/game/club`, apiConfig)
			dispatch({ type: GET_GAME_BY_CLUB, payload: response.payload.games })
		}
		catch (e) {
			console.log(e)
			if (e.apiResponse.code !== 404) {
				dispatch({
					type: SET_ALERT,
					payload: { ...initialAlertState, open: true, message: e.apiResponse.message, type: "error", position: initialAlertState.position }
				}
				)
			}
			dispatch({ type: CHANGE_LOADER, payload: false })
		}
	}
}

export const getChampionshipList = (rowsLimit, rowsOffset) => {
	return async function (dispatch) {
		try {
			if (getAccessToken() == "") {
				dispatch({ type: LOGIN, payload: false })
				dispatch({ type: GET_PROFILE, payload: false })
				dispatch({ type: GET_PLAYER_INFO, payload: null })
			}

			const apiData = {
				rowsLimit: rowsLimit,
				rowsOffset: rowsOffset,
			}

			const apiConfig = {
				method: "POST",
				headers: {
					"Accept": "application/json",
				},
				body: JSON.stringify(apiData)
			}
			dispatch({ type: CHANGE_LOADER, payload: true })
			const response = await httpReguest(`${ApiUrl}championships`, apiConfig)
			dispatch({ type: GET_CHAMPIONSHIP_LIST, payload: response.payload.championships })
			dispatch({ type: CHANGE_LOADER, payload: false })
		}
		catch (e) {
			console.log(e)
			dispatch({ type: CHANGE_LOADER, payload: false })
			if (e.apiResponse.code !== 404) {
				dispatch({
					type: SET_ALERT,
					payload: { ...initialAlertState, open: true, message: e.apiResponse.message, type: "error", position: initialAlertState.position }
				}
				)
			}
		}
	}
}

export const getChampionshipSelect = (id) => {
	return async function (dispatch) {
		try {
			if (getAccessToken() == "") {
				dispatch({ type: LOGIN, payload: false })
				dispatch({ type: GET_PROFILE, payload: false })
				dispatch({ type: GET_PLAYER_INFO, payload: null })
			}

			const apiConfig = {
				method: "GET",
				headers: {
					"Accept": "application/json",
				},
			}
			dispatch({ type: CHANGE_LOADER, payload: true })
			const response = await httpReguest(`${ApiUrl}championship/games/${id}`, apiConfig)
			dispatch({ type: SELECT_CHAMPIONSHIP, payload: response.payload.championshipGames })
			dispatch({ type: CHANGE_LOADER, payload: false })

		}
		catch (e) {
			console.log(e)
			dispatch({ type: CHANGE_LOADER, payload: false })
			if (e.apiResponse.code !== 404) {
				dispatch({
					type: SET_ALERT,
					payload: { ...initialAlertState, open: true, message: e.apiResponse.message, type: "error", position: initialAlertState.position }
				}
				)
			}
		}
	}
}

export const getChampionshipClubs = (id) => {
	return async function (dispatch) {
		try {
			if (getAccessToken() == "") {
				dispatch({ type: LOGIN, payload: false })
				dispatch({ type: GET_PROFILE, payload: false })
				dispatch({ type: GET_PLAYER_INFO, payload: null })
			}

			const apiConfig = {
				method: "GET",
				headers: {
					"Accept": "application/json",
				},
			}
			dispatch({ type: CHANGE_LOADER, payload: true })
			const response = await httpReguest(`${ApiUrl}championship/clubs/${id}`, apiConfig)
			dispatch({ type: GET_CHAMPIONSHIP_CLUBS, payload: response.payload.championshipsClubs })
			dispatch({ type: CHANGE_LOADER, payload: false })
		}
		catch (e) {
			console.log(e)
			dispatch({ type: CHANGE_LOADER, payload: false })
			if (e.apiResponse.code !== 404) {
				dispatch({
					type: SET_ALERT,
					payload: { ...initialAlertState, open: true, message: e.apiResponse.message, type: "error", position: initialAlertState.position }
				}
				)
			}
		}
	}
}


export const getChampionGames = (id) => {
	return async function (dispatch) {
		try {
			if (getAccessToken() == "") {
				dispatch({ type: LOGIN, payload: false })
				dispatch({ type: GET_PROFILE, payload: false })
				dispatch({ type: GET_PLAYER_INFO, payload: null })
			}
			const apiConfig = {
				method: "GET",
				headers: {
					"Accept": "application/json",
				},
			}
			dispatch({ type: CHANGE_LOADER, payload: true })
			const response = await httpReguest(`${ApiUrl}championship/${id}`, apiConfig)
			if (response.payload.toss) {
				dispatch(getChampionshipSelect(id));
				dispatch({type: SET_CHAMPIONSHIP_ITEM_NAME, payload: response.payload.name})
				dispatch({ type: SET_TOSS, payload: true })
				dispatch({ type: CHANGE_LOADER, payload: false })
				return
			}
			else {
				if (response.payload.totalNumOfClubs === 8) {
					dispatch({ type: SELECT_CHAMPIONSHIP, payload: Games8 })
					dispatch({ type: CHANGE_LOADER, payload: false })
					dispatch({ type: SET_TOSS, payload: false })
					return
				}
				else {
					dispatch({ type: SELECT_CHAMPIONSHIP, payload: Games16 })
					dispatch({ type: CHANGE_LOADER, payload: false })
					dispatch({ type: SET_TOSS, payload: false })
					return
				}
			}
		}
		catch (e) {
			console.log(e)
			dispatch({ type: CHANGE_LOADER, payload: false })
			if (e.apiResponse.code !== 404) {
				dispatch({
					type: SET_ALERT,
					payload: { ...initialAlertState, open: true, message: e.apiResponse.message, type: "error", position: initialAlertState.position }
				}
				)
			}
		}
	}
}


export const championshipJoinClub = (championshipId, clubId) => {
	return async function (dispatch) {
		try {
			if (getAccessToken() == "") {
				dispatch({ type: LOGIN, payload: false })
				dispatch({ type: GET_PROFILE, payload: false })
				dispatch({ type: GET_PLAYER_INFO, payload: null })
			}

			const apiData = {
				championshipId: championshipId.toString(),
				clubId: clubId.toString(),
			}

			const apiConfig = {
				method: "POST",
				headers: {
					"Accept": "application/json",
				},
				body: JSON.stringify(apiData)
			}
			dispatch({ type: CHANGE_LOADER, payload: true })
			const response = await httpReguest(`${ApiUrl}client/championships/join`, apiConfig)
			dispatch({ type: CHANGE_LOADER, payload: false })
			dispatch(getChampionshipClubs(championshipId))
			dispatch({
				type: SET_ALERT,
				payload: { ...initialAlertState, open: true, message: 'Вы успешно подали заявку на чемпионат!', type: "success", position: initialAlertState.position }
			}
			)
		}
		catch (e) {
			console.log(e)
			dispatch({ type: CHANGE_LOADER, payload: false })
			if (e.apiResponse.code !== 404) {
				dispatch({
					type: SET_ALERT,
					payload: { ...initialAlertState, open: true, message: e.apiResponse.message, type: "error", position: initialAlertState.position }
				}
				)
			}
		}
	}
}

export const getSchools = () => {
	return async function (dispatch) {
		try {
			if (getAccessToken() == "") {
				dispatch({ type: LOGIN, payload: false })
				dispatch({ type: GET_PROFILE, payload: false })
				dispatch({ type: GET_PLAYER_INFO, payload: null })
			}

			const apiData = {
				"name": "",
				"city": "",
				"phone1": "",
				"ageFrom": "",
				"ageTo": "",
				"rowsLimit": 25,
				"rowsOffset": 0
			}

			const apiConfig = {
				method: "POST",
				headers: {
					"Accept": "application/json",
				},
				body: JSON.stringify(apiData),
			}
			dispatch({ type: SCHOOLS_LOADER, payload: true })
			const response = await httpReguest(`${ApiUrl}schools`, apiConfig)
			dispatch({ type: GET_SCHOOLS, payload: response.payload.schools })
			dispatch({ type: SCHOOLS_LOADER, payload: false })
		}
		catch (e) {
			console.log(e)
			dispatch({ type: SCHOOLS_LOADER, payload: false })
			if (e.apiResponse.code !== 404) {
				dispatch({
					type: SET_ALERT,
					payload: { ...initialAlertState, open: true, message: e.apiResponse.message, type: "error", position: initialAlertState.position }
				}
				)
			}
		}
	}
}

export const getServices = () => {
	return async function (dispatch) {
		try {
			if (getAccessToken() == "") {
				dispatch({ type: LOGIN, payload: false })
				dispatch({ type: GET_PROFILE, payload: false })
				dispatch({ type: GET_PLAYER_INFO, payload: null })
			}

			const apiData = {
				"name": "",
				"phone1": "",
				"rowsLimit": 20,
				"rowsOffset": 0
			}

			const apiConfig = {
				method: "POST",
				headers: {
					"Accept": "application/json",
				},
				body: JSON.stringify(apiData),
			}
			dispatch({ type: SERVICES_LOADER, payload: true })
			const response = await httpReguest(`${ApiUrl}services`, apiConfig)
			dispatch({ type: GET_SERVICES, payload: response.payload.services })
			dispatch({ type: SERVICES_LOADER, payload: false })
		}
		catch (e) {
			console.log(e)
			dispatch({ type: SERVICES_LOADER, payload: false })
			if (e.apiResponse.code !== 404) {
				dispatch({
					type: SET_ALERT,
					payload: { ...initialAlertState, open: true, message: e.apiResponse.message, type: "error", position: initialAlertState.position }
				}
				)
			}
		}
	}
}