import { CHANGE_LOADER, GET_INVITERS, GET_REQUEST, INVITERS_ERROR } from "../actions"


const initialData = {
    inviters: [],
    requestes: [],
    loadingInviters: false,
    error: false,
}
export const invitersReducer = (state = initialData, action) => {
    switch (action.type) {
        case CHANGE_LOADER:
            return {...state, loadingInviters: action.payload}
        case GET_INVITERS:
            return {...state, inviters: action.payload}
        case GET_REQUEST:
            return {...state, requestes: action.payload}      
        case INVITERS_ERROR:
            return {...state, error: action.payload}     
        default: 
            return state    
    }
}