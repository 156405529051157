import { GET_SCHOOLS, SCHOOLS_LOADER } from '../actions';


const initialState = {
	schools: [],
	loading: false,
}

export const schoolsReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_SCHOOLS:
			return { ...state, schools: action.payload };
		case SCHOOLS_LOADER:
			return { ...state, loading: action.payload }
		default:
			return state;
	}
}