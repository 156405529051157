import { CHANGE_LOADER, ERROR_STADIUM, GET_STADIUM } from "../actions"


const initialState = {
    stadiums: [],
    loading: false,
    error: false,
}


export const stadiumsListReducer = (state = initialState, action) => {
    switch(action.type) {
        case GET_STADIUM:
            return {...state, stadiums: action.payload}
        case CHANGE_LOADER:
            return {...state, loading: action.payload}
        case ERROR_STADIUM:
            return {...state, error: action.payload}    
        default:
            return state        
    }
}