import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Tooltip } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import p from "./Players.module.css";
import player from "../../../assets/streetfootball5.jpg";
import { NavLink, useHistory } from "react-router-dom";
import playerIcon from "../../../assets/playerIcon.jpg";
import barc from "../../../assets/barc.png";
import { FilterListOutlined, SearchOutlined } from "@material-ui/icons";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getPlayers, getStates, invitePlayer } from "../../../store/actions";
import Loader from "../../../utils/Loader";
import { DebounceInput } from "react-debounce-input";
import PlayerView from "./modals/PlayerView";
import ListItem from "@material-ui/core/ListItem";
import info from "../../../assets/info.svg";
import addPlayer from "../../../assets/soccer-player.svg";
import user from "../../../assets/user.svg";
import { SkletonImg } from "../../../utils/SkletonImg";
import { imgUrl } from '../../../config';
import ClubCardWithName from '../../../utils/ClubCardWithName';
import PlayerInvite from './modals/PlayerInvite';
import PhoneHide from '../../../utils/PhoneHide/PhoneHide';
import { ageFormat } from "../../../utils/AgeFormat/ageFormat";
import ClubViewModal from "../Clubs/modals/ClubInvite/ClubViewModal";

const useStyles = makeStyles((theme) => ({
	button: {
		display: "block",
		marginTop: theme.spacing(2),
	},
	formControl: {
		minWidth: 120,
	},
}));

export const parseImagePath = (string) => {
	if (string == undefined) {
		return;
	}
	const newSrc = string.split("/")[2];
	return newSrc;
};

const Players = () => {
	const classes = useStyles();
	const history = useHistory();
	const [openInvite, setOpenInvite] = useState(false);
	const [openViewModal, setOpenViewModal] = useState(false);
	const [openViewClubModal, setOpenViewClubModal] = useState(false);
	const [playerSelect, setPlayerSelect] = useState("");
	const [filter, setFilter] = useState(false);
	const [rowsOffset, setRowsOffset] = useState(0);
	const [hasClub, setHasClub] = useState("");
	const [statusId, setStatusId] = useState("");
	const [rowsLimit, setRowsLimit] = useState(15);
	const dispatch = useDispatch();
	const { players, loading, pagination, error } = useSelector((state) => state.playersListReducer, shallowEqual);
	const { status } = useSelector((state) => state.homeReducer, shallowEqual);
	const { clubId } = useSelector((state) => state.clubsListReducer, shallowEqual);
	const { playerInfo, auth, hasClub: hasClubPlayer } = useSelector((state) => state.loginReducer, shallowEqual);

	const playerDate = {
		name: "",
		phone: "",
		ageFrom: "",
		ageTo: "",
	};

	const [state, setState] = useState(playerDate);
	const { name, phone, ageFrom, ageTo } = state;

	const handleChangeClub = (e) => {
		setHasClub(e.target.value);
		setRowsOffset(0);
	};

	const handleChangeStatusId = (e) => {
		setStatusId(e.target.value);
		setRowsOffset(0);
	};

	const handleChange = (e) => {
		setState({ ...state, [e.target.name]: e.target.value });
		setRowsOffset(0);
	};

	useEffect(() => {
		dispatch(getPlayers(name, phone, ageFrom, ageTo, hasClub, statusId, rowsOffset, rowsLimit));
	}, [rowsOffset, hasClub, statusId, name, phone, ageFrom, ageTo, statusId]);

	return (
		<div className={p.container}>

			<div className={p.controlPanel} style={filter ? { height: "auto" } : { height: "46px" }}>
				<div className={p.searchPanel}>
					<Button variant="outlined" startIcon={<FilterListOutlined />} onClick={() => setFilter(!filter)}>
						Фильтр
					</Button>
					{!auth &&
						<Button onClick={() => history.push('/player-sign-up')} className={p.mainBlockBtn}>
							Создать игрока
						</Button>
					}
				</div>
				<div className={p.filterPanel}>
					<Grid container className={p.filterContainer}>
						<Grid item className={p.filterItem}>
							<DebounceInput
								name="name"
								debounceTimeout={1000}
								value={name}
								onChange={handleChange}
								label="Имя"
								element={TextField}
							/>
						</Grid>
						<Grid item className={p.filterItem}>
							<DebounceInput
								name="phone"
								debounceTimeout={1000}
								value={phone}
								onChange={handleChange}
								label="Телефон"
								element={TextField}
							/>
						</Grid>
						<Grid item className={p.filterItem}>
							<DebounceInput
								name="ageFrom"
								debounceTimeout={1000}
								value={ageFrom}
								onChange={handleChange}
								label="От лет"
								element={TextField}
							/>
						</Grid>
						<Grid item className={p.filterItem}>
							<DebounceInput
								name="ageTo"
								debounceTimeout={1000}
								value={ageTo}
								onChange={handleChange}
								label="До лет"
								element={TextField}
							/>
							{+ageFrom > +ageTo && ageTo != "" ? (
								<div style={{ color: "red", fontSize: "10px", width: "100%", margin: 0, lineHeight: "20px" }}>
									Значение должен быть больше передущего
								</div>
							) : null}
						</Grid>
						<Grid item className={p.filterItem}>
							<FormControl className={classes.formControl}>
								<InputLabel shrink id="demo-simple-select-placeholder-label-label">
									Тип игрока
								</InputLabel>
								<Select
									labelId="demo-simple-select-placeholder-label-label"
									id="demo-simple-select-placeholder-label"
									value={hasClub}
									name="hasClub"
									onChange={handleChangeClub}
									displayEmpty
									className={classes.selectEmpty}
								>
									<MenuItem value="">Все</MenuItem>
									<MenuItem value={"false"}>Без клуба</MenuItem>
									<MenuItem value={"true"}>Есть клуб</MenuItem>
								</Select>
							</FormControl>
						</Grid>
						<Grid item className={p.filterItem}>
							<FormControl className={classes.formControl}>
								<InputLabel shrink id="demo-simple-select-placeholder-label-label">
									Статус игрока
								</InputLabel>
								<Select
									labelId="demo-simple-select-placeholder-label-label"
									id="demo-simple-select-placeholder-label"
									value={statusId}
									name="statusId"
									onChange={handleChangeStatusId}
									displayEmpty
									className={classes.selectEmpty}
								>
									<MenuItem value="">Все</MenuItem>
									{status.map((state, idx) => (
										<MenuItem key={idx} value={state.id}>
											{state.name}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid>
					</Grid>
				</div>
			</div>
			<>
				{loading ? (
					<Loader />
				) : (
					<>
						{error ? (
							<h3 style={{ textAlign: "center" }}>Нет таких игроков</h3>
						) : (
							<div className={p.playersList}>
								{players.map((player, idx) => (
									<Grid container className={p.playerItem} style={idx == 19 ? { marginBottom: 0 } : null} key={idx}>
										<ListItem>
											<Grid item xs={3} className={p.playerIcon}>
												<div onClick={() => {
													setOpenViewModal(true);
													setPlayerSelect(player);
												}} className={p.playerIcon}>
													<div
														className={p.infoPlayer}
													>
														<Tooltip title="Подробне" placement="top">
															<img src={info} />
														</Tooltip>
													</div>
													{player.imagePath == "" ? (
														<img src={user} alt={user} />
													) : (
														<SkletonImg
															path={`${imgUrl}${parseImagePath(player.imagePath)}`}
															skletonClass={p.playerIconSkleton}
														/>
													)}
													{player.name.length > 8 ? player.name.slice(0, 8) : player.name} {player.surname.slice(0, 1)}.
												</div>
											</Grid>
											<Grid item xs={3} className={p.clubIcon}>
												<b className={p.playerAge}>{player.age} <b className={p.playerAgeWord}>{ageFormat(player.age)}</b></b>
											</Grid>
											<Grid item xs={3} className={p.clubIcon}>
												{player.clubName != "" ? (
													<>
														{player.clubImg === "" ?
															<>
																<ClubCardWithName name={player.clubName} />
																<span style={{ textAlign: 'center' }}>{player.clubName}</span>
															</>
															:
															<>
																<div onClick={() => {setOpenViewClubModal(true); setPlayerSelect(player);}}>
																	<SkletonImg
																		path={`${imgUrl}${parseImagePath(player.clubImg)}`}
																		skletonClass={p.playerIconSkleton}
																	/>
																</div>
																<b style={{ textAlign: 'center' }}>{player.clubName}</b>
															</>
														}
													</>
												) : (
													<>
														<ClubCardWithName name={'-'} />
														<span style={{ textAlign: 'center' }}>Без клуба</span>
													</>
												)}
											</Grid>
											<Grid item xs={3} className={p.action}>
												{hasClubPlayer && playerInfo?.isManager && player.clubName === "" ?
													<div className={p.addPlayer}>
														<Tooltip
															title="Пригласить игрока"
															placement="top"
															onClick={() => { setPlayerSelect(player); setOpenInvite(true) }}
														>
															<img src={addPlayer} />
														</Tooltip>
													</div>
													: null
												}
											</Grid>
										</ListItem>
									</Grid>
								))}
							</div>
						)}
						<div className={p.endPanel}>
							<Button disabled={rowsOffset == 0} onClick={() => setRowsOffset(rowsOffset - 1)}>{`<`}</Button>
							<div>{rowsOffset + 1}</div>
							<Button onClick={(e) => setRowsOffset(rowsOffset + 1)} disabled={rowsOffset == pagination}>{`>`}</Button>
						</div>
					</>
				)}
			</>
			<PlayerView show={openViewModal} onClose={() => setOpenViewModal(false)} playerItem={playerSelect} />
			<PlayerInvite show={openInvite} onClose={() => setOpenInvite(false)} data={{ clubId, inviter: "club", playerId: playerSelect.id }} />
			<ClubViewModal show={openViewClubModal} onClose={() => setOpenViewClubModal(false)} clubId={playerSelect?.clubId} />
		</div>
	);
};

export default Players;
