import React from 'react';
import styles from './SuspenseFallback.module.css';
// Images
import logo from '../../assets/logo.svg';

const SuspenseFallback = ({ title = "Заголовок пустой", ...props }) => {

	return (
		<div className={styles.imageContainer}>
			<img src={logo} className={styles.brandLogo} style={{ borderRadius: "50%" }} />
		</div>
	);
}

export default SuspenseFallback;