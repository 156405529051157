import React, { useEffect, useState } from 'react';
import classes from './PlayerInvite.module.css';
import { Button, Dialog, DialogContent, IconButton, TextField, Typography, withStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { invitePlayer } from '../../../../store/actions';
import Loader from '../../../../utils/Loader';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';

const styles = (theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing(2),
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500],
	},
});

const DialogTitle = withStyles(styles)((props) => {
	const { children, classes, onClose, ...other } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root} {...other}>
			<Typography variant="h6">{children}</Typography>
			{onClose ? (
				<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
					<CloseIcon />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
});

const PlayerInvite = ({ show, onClose, data }) => {
	const dispatch = useDispatch();
	const { clubId, inviter, playerId } = data;
	const [number, setNumber] = useState('');
	const loading = useSelector((state) => state.invitersReducer.loadingInviters)

	const invitePlayerSubmit = () => {
		dispatch(invitePlayer(playerId, clubId, inviter, number, onClose))
	}

	useEffect(() => {
		if (!show) {
			setNumber('')
		}
	}, [show])

	return (
		<>
			<Dialog
				open={show}
				maxWidth={'md'}
				onClose={onClose}
			>
				<DialogTitle id="customized-dialog-title" onClose={onClose}>
					Приглашение игрока
				</DialogTitle>
				<DialogContent>
					<div className={classes.modalContent}>
						<TextField
							className={classes.inp}
							autoFocus
							margin="dense"
							id="name"
							label="Номер игрока"
							type="number"
							fullWidth
							value={number}
							onChange={(e) => setNumber(e.target.value)}
						/>
					</div>
					<div className={classes.modalBtnBlock}>
						{loading ? <Loader /> :
							<Button onClick={invitePlayerSubmit} variant="contained" color="primary" className={classes.btn}>Подтвердить</Button>
						}
					</div>
				</DialogContent>
			</Dialog>
		</>
	)
}

export default PlayerInvite;