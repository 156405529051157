export const ageFormat = (age) => {
    if (age >= 1 && age <= 4) {
        return 'г.'
    } else if (age >= 5 && age <= 19) {
        return 'л.'
    } else {
        if (age.toString().length === 2) {
            if (age.toString()[1] >= 1 && age.toString()[1] <= 4) {
                return 'г.'
            } else {
                return 'л.'
            }
        }
    }
}