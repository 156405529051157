import { LEAVE_LOADING } from '../actions/LeaveClub'


const initialState = {
	leaveLoading: false,
}


export const leaveClubReducer = (state = initialState, action) => {
	switch (action.type) {
		case LEAVE_LOADING:
			return { ...state, leaveLoading: action.payload };
		default:
			return state
	}
}