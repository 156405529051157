import { CHANGE_LOADER, ERROR_PLAYER, GET_PLAYERS, PAGINATION_PLAYER, PLAYER_ID } from "../actions";
import { CHANGE_PLAYER_LOADER, SET_PLAYER_CLUB_HISTORY } from '../actions/PlayerHistory';


const initialState = {
	loading: false,
	players: [],
	pagination: 0,
	playersById: [],
	loadingPlayer: false,
	error: false,
	clubHistory: [],
}

const playersListReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_PLAYERS:
			return { ...state, players: action.payload }
		case CHANGE_LOADER:
			return { ...state, loading: action.payload }
		case PAGINATION_PLAYER:
			return { ...state, pagination: action.payload }
		case PLAYER_ID:
			return { ...state, playersById: action.payload }
		case ERROR_PLAYER:
			return { ...state, error: action.payload }
		case CHANGE_PLAYER_LOADER:
			return { ...state, loadingPlayer: action.payload }
		case SET_PLAYER_CLUB_HISTORY:
			return { ...state, clubHistory: action.payload }
		default:
			return state;
	}
}

export default playersListReducer;
