import { Skeleton } from '@material-ui/lab';
import React, { useEffect, useState } from 'react'

export const SkletonImg = ({ path, componentClass, skletonClass, type = "circle"}) => {
    const [imgUrl, setImgUrl] = useState(path);
    // useEffect(() => {
    //   const sendRequest = async () => {
    //     const response = await fetch(`${path}`);
    //     const blobFile = await response.blob();
    //     const objectURL = await window.URL.createObjectURL(blobFile);
    //     setImgUrl(objectURL);
    //   };
    //   sendRequest();
    // }, [path]);
  
    return (
      <>{imgUrl ? <img src={imgUrl} alt={path} className={componentClass} /> : <Skeleton variant={type} className={skletonClass} />}</>
    );
};
  