import { Grid } from '@material-ui/core';
import React, { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getClubs, getStates } from '../../../store/actions';
import { getMonth } from '../../../utils/httpFunctions';
import h from './Home.module.css'
import barc from '../../../assets/barc.png'
import real from '../../../assets/real.png'
import Loader from '../../../utils/Loader';
import { parseImagePath } from '../Players/Players';
import { SkletonImg } from '../../../utils/SkletonImg';
import { imgUrl } from '../../../config';
import { Skeleton } from '@material-ui/lab';
import ClubCardWithName from '../../../utils/ClubCardWithName';
import { Premiere } from "../../Lazy/Lazy";

const Home = () => {
	const dispatch = useDispatch()
	const { games, premiere, gameDate, loading } = useSelector((state) => state.homeReducer, shallowEqual)
	const dateHour = premiere?.gameDate?.split(" ")[1].slice(0, 5)

	useEffect(() => {
		dispatch(getClubs())
	}, [])

	return (
		<div className={h.container}>
			<Premiere />
			<>
				{loading ?
					<Loader />
					:
					<div>
						<h3 className={h.titleTableGame}>Расписание игр</h3>
						<div className={h.tableItemDesktop}>
							<Grid container className={h.tableContainer}>
								{games.map((item, idx) =>
									<Grid item key={idx} container className={h.tableItem}>
										<Grid item xs={4} className={h.firstClub}>
											{item.inviterImage.trim() == "" ?
												<ClubCardWithName name={item.inviter} width={40} height={40} />
												:
												<SkletonImg path={`${imgUrl}${parseImagePath(item.inviterImage)}`} componentClass={h.tableClubImg} skletonClass={h.tableClubImgSkleton} />
											}
											<div className={h.tableClubName1}>{item.inviter}</div>
										</Grid>
										<Grid item xs={4} className={h.tableGameHour}>
											<div>{`${getMonth(item.gameDateFrom)}`}</div>
											<div>{item.stadium}</div>
										</Grid>
										<Grid item xs={4} className={h.secondClub}>
											<div className={h.tableClubName2}>{item.invitee}</div>
											{item.inviteeImage.trim() == "" ?
												<ClubCardWithName name={item.invitee} width={40} height={40} />
												:
												<SkletonImg path={`${imgUrl}${parseImagePath(item.inviteeImage)}`} componentClass={h.tableClubImg} skletonClass={h.tableClubImgSkleton} />
											}
										</Grid>
									</Grid>
								)}
							</Grid>
						</div>
						<div className={h.tableItemMobile}>
							<Grid container className={h.tableContainer}>
								{games.map((item, idx) =>
									<Grid key={idx} item container className={h.tableItemMobileContainer}>
										<Grid item xs={7}>
											<div className={h.firstItemMobile}>
												{item.inviterImage.trim() == "" ?
													<ClubCardWithName name={item.inviter} width={40} height={40} />
													:
													<SkletonImg path={`${imgUrl}${parseImagePath(item.inviterImage)}`} componentClass={h.tableClubImg} skletonClass={h.tableClubImgSkleton} />
												}
												<div >{item.inviter}</div>
											</div>
											<div className={h.secondItemMobile}>
												{item.inviteeImage.trim() == "" ?
													<ClubCardWithName name={item.invitee} width={40} height={40} />
													:
													<SkletonImg path={`${imgUrl}${parseImagePath(item.inviteeImage)}`} componentClass={h.tableClubImg} skletonClass={h.tableClubImgSkleton} />
												}
												<div >{item.invitee}</div>
											</div>
										</Grid>
										<Grid item xs={5} className={h.tableGameDateMobile}>
											<div >
												<div>{`${getMonth(item.gameDateFrom)}`}</div>
												<div>{item.stadium}</div>
											</div>
										</Grid>
									</Grid>
								)}
							</Grid>
						</div>
					</div>
				}
			</>
		</div>
	)
}

export default Home;

