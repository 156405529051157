import React from 'react';
import styles from './PlayerItem.module.css';
import user from "../../../../../assets/user.svg";
import { parseImagePath } from '../../../Players/Players';
import { imgUrl } from '../../../../../config';
import { SkletonImg } from '../../../../../utils/SkletonImg';


const PlayerItem = ({ position, fullName,number, img, }) => {
    return (
        <div className={styles.container}>
            <div className={styles.row}>
                <div className={styles.value_with_img}>
                    {img == "" ? (
                        <img src={user} alt={user} width={35} />
                    ) : (
                        <SkletonImg
                            path={`${imgUrl}${parseImagePath(img)}`}
                            skletonClass={styles.playerIconSkleton}
                            componentClass={styles.image}
                        />
                    )}
                    {fullName}
                </div>
            </div>
            <div className={styles.row}>
                <div className={styles.value}>{number}</div>
            </div>
            <div className={styles.row}>
                <div className={styles.value}>{position}</div>
            </div>

        </div>
    )
}

export default PlayerItem